import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  loadAppointmentsData,
  submitUpdateAppointment,
  submitUpdateAppointmentStatus,
  loadTimeSlotsData,
  submitAddTimeSlot,
  submitUpdateTimeSlot,
  loadDayOffsData,
  submitAddDayOff,
  submitUpdateDayOff,
  loadEmailTemplatesData,
  submitUpdateEmailTemplate,
  loadUsersData,
  loadAdminUsersData,
  loadDoctorUsersData,
  submitUpdateDoctor,
  submitAddDoctor,
  submitAddUser,
  submitUpdateUser,
  loadDynamicImagesData,
} from "./actions";
import { loadSlotsData } from "../Scheduler/actions";
import Page from "./Page";

class AdminCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const AdminForm = Form.create({ name: "admin_form" })(AdminCard);

function mapStateToProps(state) {
  return {
    isLoading: state.admin.isLoading,
    appointmentsData: state.admin.appointmentsData,
    slotsData: state.scheduler.slotsData,
    timeSlotsData: state.admin.timeSlotsData,
    dayOffsData: state.admin.dayOffsData,
    emailTemplatesData: state.admin.emailTemplatesData,
    usersData: state.admin.userData,
    adminUsersData: state.admin.adminUserData,
    doctorUsersData: state.admin.doctorUserData,
    dynamicImagesData: state.admin.dynamicImagesData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadAppointmentsData: (data) => dispatch(loadAppointmentsData(data)),
    onSubmitUpdateAppointment: (data) =>
      dispatch(submitUpdateAppointment(data)),
    onSubmitUpdateAppointmentStatus: (data) =>
      dispatch(submitUpdateAppointmentStatus(data)),
    onLoadSlotsData: (data) => dispatch(loadSlotsData(data)),

    onLoadTimeSlotsData: (data) => dispatch(loadTimeSlotsData(data)),
    onSubmitAddTimeSlot: (data) => dispatch(submitAddTimeSlot(data)),
    onSubmitUpdateTimeSlot: (data) => dispatch(submitUpdateTimeSlot(data)),

    onLoadDayOffsData: (data) => dispatch(loadDayOffsData(data)),
    onSubmitAddDayOff: (data) => dispatch(submitAddDayOff(data)),
    onSubmitUpdateDayOff: (data) => dispatch(submitUpdateDayOff(data)),

    onLoadEmailTemplatesData: (data) => dispatch(loadEmailTemplatesData(data)),
    onSubmitUpdateEmailTemplate: (data) =>
      dispatch(submitUpdateEmailTemplate(data)),

    onLoadUsersData: (data) => dispatch(loadUsersData(data)),
    onLoadAdminUsersData: (data) => dispatch(loadAdminUsersData(data)),
    onLoadDoctorUsersData: (data) => dispatch(loadDoctorUsersData(data)),
    onSubmitAddUser: (data) => dispatch(submitAddUser(data)),
    onSubmitUpdateUser: (data) => dispatch(submitUpdateUser(data)),
    onSubmitAddDoctor: (data) => dispatch(submitAddDoctor(data)),
    onSubmitUpdateDoctor: (data) => dispatch(submitUpdateDoctor(data)),

    onLoadDynamicImagesData: (id) => dispatch(loadDynamicImagesData(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminForm);
