import React from "react";
import Logo from "../Logo";
import { Layout } from "antd";
export default function Header(props) {
  return (
    <Layout.Header className="md-header">
      <Logo {...props} />
    </Layout.Header>
  );
}
