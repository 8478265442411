import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Spin, Modal } from "antd";
import "./index.scss";
import Logo from "../../assets/images/md-scheduler-logo.png";
import { getToken } from "../../utils/token";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgot: false,
    };
  }

  showForgotModal = (e) => {
    this.setState({ showForgot: true });
  };

  handleForgotPassword = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["email"], async (err, values) => {
      if (!err) {
        let params = values;
        params.client_id = process.env.REACT_APP_CLIENT_ID;

        await this.props.onSubmitForgot(params).then((response) => {
          if (response.error) {
            Modal.success({
              title: "Unable to process request.",
              content: "",
            });
          } else {
            console.log("response", response);
            Modal.success({
              title: "An email reset link will be sent to your email address.",
              content: "",
            });
          }
        });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["username", "password"], (err, values) => {
      if (!err) {
        let params = values;
        params.client_id = process.env.REACT_APP_CLIENT_ID;
        this.props.onSubmitLogin(values);
      }
    });
  };

  componentDidMount() {
    if (getToken()) {
      const { history } = this.props;
      history.push("/admin");
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      loggingIn,
      loginRejected,
    } = this.props;

    const { showForgot } = this.state;

    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        className="login-container"
      >
        <Col xs={20} sm={15} md={15} lg={10}>
          <Spin spinning={loggingIn}>
            <Row
              type="flex"
              justify="start"
              align="middle"
              className="login-header"
            >
              <Col span={24}>
                <img
                  src={Logo}
                  alt="login-logo"
                  style={{ objectFit: "contain", height: "150px" }}
                />
              </Col>
              <Col span={24}>
                <h2>EasyReach</h2>
                {loginRejected && (
                  <div className="login-error-message">
                    Login Failed: Invalid Credentials
                  </div>
                )}
              </Col>
            </Row>

            <Form onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Username",
                    },
                  ],
                })(<Input placeholder="Username" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Password",
                    },
                  ],
                })(<Input type="password" placeholder="Password" />)}
              </Form.Item>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="login-footer"
              >
                {/* <Col xs={24} md={15}>
                  <p>
                    This code is located at the back of your included in your
                    companion kit.
                  </p>
                </Col> */}
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="form-btn-submit"
                  >
                    Login
                  </Button>
                </Col>
                <Col span={24} align="right" className="is-register">
                  <a onClick={this.showForgotModal}>Forgot password</a>
                </Col>
              </Row>
            </Form>
          </Spin>

          <Modal
            visible={showForgot}
            footer={null}
            closable={false}
            oonCancel={this.showForgot}
            centered={true}
            // width={600}
            className="forgot-modal"
          >
            <Form onSubmit={this.handleForgotPassword}>
              <p>
                Please enter your email and we will send you link to reset your
                password.
              </p>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Enter your Email",
                    },
                    {
                      type: "email",
                      message: "Invalid email format",
                    },
                  ],
                })(<Input placeholder="sample@email.com" />)}
              </Form.Item>
              <Row
                type="flex"
                justify="space-between"
                align="top"
                className="login-footer"
              >
                <Col span={24} align="right">
                  <Button
                    onClick={() => this.setState({ showForgot: false })}
                    type="default"
                    style={{ marginRight: "20px" }}
                  >
                    Close
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Col>
      </Row>
    );
  }
}

const LoginForm = Form.create({ name: "login_form" })(Login);

export default LoginForm;
