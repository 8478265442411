const initialData = {
  isLoading: false,
  appointmentsData: [],
  timeSlotsData: [],
  dayOffsData: [],
  emailTemplatesData: [],
  userData: [],
  doctorUserData: [],
  adminUserData: [],
  dynamicImagesData: [],
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    // APPOINTMENTS -----------------------------------------
    case "GET_APPOINTMENTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_APPOINTMENTS_SUCCESS":
      return {
        ...state,
        appointmentsData: action.payload,
        isLoading: false,
      };
    case "GET_APPOINTMENTS_FAILED":
      return {
        ...state,
        appointmentsData: [],
        isLoading: false,
      };

    case "POST_APPOINTMENTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_APPOINTMENTS_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "POST_APPOINTMENTS_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    // TIME SLOTS -----------------------------------------
    case "GET_TIME_SLOTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TIME_SLOTS_SUCCESS":
      return {
        ...state,
        timeSlotsData: action.payload,
        isLoading: false,
      };
    case "GET_TIME_SLOTS_FAILED":
      return {
        ...state,
        timeSlotsData: [],
        isLoading: false,
      };

    case "POST_TIME_SLOTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_TIME_SLOTS_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "POST_TIME_SLOTS_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    // DAY OFFS -----------------------------------------
    case "GET_DAY_OFFS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DAY_OFFS_SUCCESS":
      return {
        ...state,
        dayOffsData: action.payload,
        isLoading: false,
      };
    case "GET_DAY_OFFS_FAILED":
      return {
        ...state,
        dayOffsData: [],
        isLoading: false,
      };

    case "POST_DAY_OFFS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_DAY_OFFS_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "POST_DAY_OFFS_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    // EMAIL TEMPLATES -----------------------------------------
    case "GET_EMAIL_TEMPLATES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_EMAIL_TEMPLATES_SUCCESS":
      return {
        ...state,
        emailTemplatesData: action.payload,
        isLoading: false,
      };
    case "GET_EMAIL_TEMPLATES_FAILED":
      return {
        ...state,
        emailTemplatesData: [],
        isLoading: false,
      };

    case "POST_EMAIL_TEMPLATES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_EMAIL_TEMPLATES_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "POST_EMAIL_TEMPLATES_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    // USERS -----------------------------------------
    case "GET_USERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        userData: action.payload,
        isLoading: false,
      };
    case "GET_USERS_FAILED":
      return {
        ...state,
        userData: [],
        isLoading: false,
      };

    case "GET_DOCTOR_USERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DOCTOR_USERS_SUCCESS":
      return {
        ...state,
        doctorUserData: action.payload,
        isLoading: false,
      };
    case "GET_DOCTOR_USERS_FAILED":
      return {
        ...state,
        doctorUserData: [],
        isLoading: false,
      };

    case "GET_ADMIN_USERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_ADMIN_USERS_SUCCESS":
      return {
        ...state,
        adminUserData: action.payload,
        isLoading: false,
      };
    case "GET_ADMIN_USERS_FAILED":
      return {
        ...state,
        adminUserData: [],
        isLoading: false,
      };

    case "POST_USERS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "POST_USERS_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "POST_USERS_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "GET_DYNAMICIMAGES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DYNAMICIMAGES_SUCCESS":
      return {
        ...state,
        dynamicImagesData: action.payload,
        isLoading: false,
      };
    case "GET_DYNAMICIMAGES_FAILED":
      return {
        ...state,
        dynamicImagesData: [],
        isLoading: false,
      };

    default:
      return {
        ...state,
      };
  }
}
