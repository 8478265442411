import React, { Component } from "react";
import {
  Form,
  Icon,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import moment from "moment";
import { tableSorter, tableSearcher } from "../../../../utils/helper";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const ButtonGroup = Button.Group;

class User extends Component {
  initialState = {
    showFormModal: false,
    selectedRow: null,
    tableData: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitAddUser, onSubmitUpdateUser } = this.props;
    const { selectedRow } = this.state;

    this.props.form.validateFields(
      [
        "firstname",
        "lastname",
        "email",
        "username",
        "password",
        "mobile",
        "status",
      ],
      async (err, values) => {
        if (!err) {
          let params = {
            ...values,
          };

          params.is_admin = 1;

          if (selectedRow) {
            params.id = selectedRow.id;
            await onSubmitUpdateUser(params).then((response) => {
              this.props.showModalResponse(
                response,
                "Admin has been updated successfully.",
                "Unable to Add Admin."
              );
            });
          } else {
            await onSubmitAddUser(params).then((response) => {
              this.props.showModalResponse(
                response,
                "Admin has been updated successfully.",
                "Unable to Add Admin."
              );
            });
          }

          this.props.form.resetFields();
          this.handleCloseModal();
          this.props.onLoadAdminUsersData();
        }
      }
    );
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = (row) => {
    this.setState({
      showFormModal: true,
      selectedRow: row,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      selectedRow: null,
    });
  };

  changeStatus = (row, status) => {
    const params = {
      id: row.id,
      status: status,
    };
    this.props.onSubmitUpdateUser(params).then((response) => {
      this.props.showModalResponse(
        response,
        "Admin has been updated successfully.",
        "Unable to Add Admin."
      );
    });
  };

  componentDidMount() {
    this.props.onLoadAdminUsersData();
  }

  handleSearch = (searchTerm, searchKeys) => {
    const { adminUsersData } = this.props;

    if (!searchTerm) {
      return this.setState({ tableData: adminUsersData });
    }

    const results = tableSearcher(searchTerm, searchKeys, adminUsersData);
    this.setState({ tableData: results });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.adminUsersData !== nextProps.adminUsersData) {
      this.setState({ tableData: nextProps.adminUsersData });
    }
  }

  render() {
    const { showFormModal, selectedRow, tableData } = this.state;
    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;
    const columns = [
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        sorter: (a, b) => {
          return tableSorter(a.username, b.username, "alphabetical");
        },
      },
      {
        title: "Full Name",
        dataIndex: "firstname",
        key: "fullname",
        sorter: (a, b) => {
          return tableSorter(
            a.firstname + " " + a.lastname,
            b.firstname + " " + b.lastname,
            "alphabetical"
          );
        },
        render: (firstname, row) => {
          return <span>{`${firstname} ${row.lastname}`}</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 200,
        filters: [
          { text: "Active", value: 1 },
          { text: "Inactive", value: 0 },
        ],
        onFilter: (value, record) => record.status === value,
        render: (status) => {
          return status === 1 ? <span>Active</span> : <span>Inactive</span>;
        },
      },
      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        align: "center",
        width: 200,
        sorter: (a, b) => {
          return tableSorter(a.created_at, b.created_at, "date");
        },
        render: (created_at) => {
          return <span>{moment(created_at).format("MMM. DD, YYYY")}</span>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        render: () => {
          return (
            <div className="col-action">
              <span>Show/Hide Details</span>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Layout>
          <Content style={{ padding: 20 }}>
            <Row>
              <Col span={12} align="right"></Col>
              <Col span={12} align="right">
                <ButtonGroup style={{ display: "flex" }}>
                  <Search
                    placeholder="Search"
                    onSearch={(value) =>
                      this.handleSearch(value, [
                        "name",
                        "created_at",
                        "material_code",
                      ])
                    }
                    enterButton
                    style={{ margin: "10px" }}
                  />
                  <Button
                    type="primary"
                    onClick={this.handleShowFormModal}
                    style={{ margin: "10px" }}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    <Icon type="plus" /> Add Staff
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={tableData}
                  columns={columns}
                  loading={isLoading}
                  onChange={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }}
                  expandRowByClick
                  expandedRowRender={(record) => {
                    return (
                      <Row>
                        <Col md={8}>
                          <Content className="action-content">
                            <Button
                              className="btn-confirm"
                              onClick={() =>
                                this.handleShowEditFormModal(record)
                              }
                            >
                              Edit
                            </Button>
                            {record.status === 1 ? (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 0)}
                              >
                                Deactivate
                              </Button>
                            ) : (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 1)}
                              >
                                Activate
                              </Button>
                            )}
                          </Content>
                        </Col>
                        <Col md={16}>
                          <Row gutter={32}>
                            <Col className="expand-row-details" span={12}>
                              <h1>Email Address</h1>
                              <p>{record.email}</p>
                            </Col>
                            <Col className="expand-row-details" span={12}>
                              <h1>Contact Number</h1>
                              <p>{record.mobile}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Layout>

        <Modal
          title={`${selectedRow ? "Edit" : "Add"} Admin`}
          visible={showFormModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form onSubmit={this.handleSubmit} className="user-form">
                  <Col span={12}>
                    <Form.Item label="First Name">
                      {getFieldDecorator("firstname", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter First Name!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.firstname : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Last Name">
                      {getFieldDecorator("lastname", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Last Name!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.lastname : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Username">
                      {getFieldDecorator("username", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Username!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.username : "",
                      })(<Input disabled={selectedRow ? true : false} />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    {selectedRow ? (
                      ""
                    ) : (
                      <Form.Item label="Password">
                        {getFieldDecorator("password", {
                          rules: [
                            {
                              required: true,
                              message: "Please enter Password!",
                            },
                          ],
                          initialValue: selectedRow ? selectedRow.password : "",
                        })(<Input type="password" />)}
                      </Form.Item>
                    )}
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Email">
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Email!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.email : "",
                      })(<Input type="email" />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Mobile">
                      {getFieldDecorator("mobile", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Mobile!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.mobile : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          { required: true, message: "Please select Status!" },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.status.toString()
                          : "",
                      })(
                        <Select placeholder="Select a Status">
                          <Option value="1">Active</Option>
                          <Option value="0">Inactive</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>
      </div>
    );
  }
}

const UserForm = Form.create({ name: "user_form" })(User);

export default UserForm;
