import React, { Component } from "react";
import {
  Form,
  Icon,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Input,
  Modal,
  Select,
  DatePicker,
} from "antd";
import { tableSorter, tableSearcher } from "../../../../utils/helper";
import moment from "moment";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const ButtonGroup = Button.Group;

const timeSlot = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

class TimeSlot extends Component {
  initialState = {
    showFormModal: false,
    selectedRow: null,
    tableData: [],
    slotType: "day",
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitAddTimeSlot, onSubmitUpdateTimeSlot } = this.props;
    const { selectedRow } = this.state;

    this.props.form.validateFields(
      ["day", "time", "status", "type", "slots"],
      async (err, values) => {
        if (!err) {
          const params = {
            ...values,
          };
          params.client_id = process.env.REACT_APP_CLIENT_ID;

          if (selectedRow) {
            params.id = selectedRow.id;
            await onSubmitUpdateTimeSlot(params).then((response) => {
              this.props.showModalResponse(
                response,
                "Time Slot has been updated successfully.",
                "Unable to update Time Slot."
              );
            });
          } else {
            await onSubmitAddTimeSlot(params).then((response) => {
              this.props.showModalResponse(
                response,
                "Time Slot has been added successfully.",
                "Unable to add Time Slot."
              );
            });
          }

          this.props.form.resetFields();
          this.handleCloseModal();
        }
      }
    );
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = (row) => {
    this.setState({
      showFormModal: true,
      selectedRow: row,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      selectedRow: null,
    });
  };

  changeStatus = (row, status) => {
    const params = {
      id: row.id,
      status: status,
    };
    this.props.onSubmitUpdateTimeSlot(params).then((response) => {
      this.props.showModalResponse(
        response,
        "Time Slot status has been updated successfully.",
        "Unable to update status of Time Slot."
      );
    });
  };

  componentDidMount() {
    this.props.onLoadTimeSlotsData();
  }

  handleSearch = (searchTerm, searchKeys) => {
    const { timeSlotsData } = this.props;

    if (!searchTerm) {
      return this.setState({ tableData: timeSlotsData });
    }

    const results = tableSearcher(searchTerm, searchKeys, timeSlotsData);
    this.setState({ tableData: results });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.timeSlotsData !== nextProps.timeSlotsData) {
      this.setState({ tableData: nextProps.timeSlotsData });
    }
  }

  handleChangeType = (type) => {
    this.setState({ slotType: type });
  };

  render() {
    const { showFormModal, selectedRow, tableData, slotType } = this.state;
    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;

    const dataSource = tableData;

    const columns = [
      {
        title: "Date / Day",
        dataIndex: "day",
        key: "day",
        sorter: (a, b) => {
          return tableSorter(a.day, b.day, "alphabetical");
        },
        render: (day, row) => {
          if (row.type == "date") {
            return moment(day).format("MM-DD-YYYY");
          }
          return day;
        },
      },
      {
        title: "Time",
        dataIndex: "time",
        key: "time",
        align: "center",
        sorter: (a, b) => {
          return tableSorter(a.time, b.time, "alphabetical");
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 200,
        filters: [
          { text: "Active", value: 1 },
          { text: "Inactive", value: 0 },
        ],
        onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          const status = row.status;
          return status === 1 ? <span>Active</span> : <span>Inactive</span>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        render: () => {
          return (
            <div className="col-action">
              <span>Show/Hide Details</span>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Layout>
          <Content style={{ padding: 20 }}>
            <Row>
              <Col xs={0} lg={12} align="right"></Col>
              <Col xs={24} lg={12} align="right">
                <ButtonGroup style={{ display: "flex" }}>
                  <Search
                    placeholder="Search"
                    onSearch={(value) =>
                      this.handleSearch(value, [
                        "name",
                        "created_at",
                        "material_code",
                      ])
                    }
                    enterButton
                    style={{ margin: "10px" }}
                  />
                  <Button
                    type="primary"
                    onClick={this.handleShowFormModal}
                    style={{ margin: "10px", padding: "5px 5px" }}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    <Icon type="plus" /> Add Time Slot
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  loading={isLoading}
                  onChange={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }}
                  expandRowByClick
                  expandedRowRender={(record) => {
                    return (
                      <Row>
                        <Col md={8}>
                          <Content className="action-content">
                            <Button
                              className="btn-confirm"
                              onClick={() =>
                                this.handleShowEditFormModal(record)
                              }
                            >
                              Edit
                            </Button>
                            {record.status === 1 ? (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 0)}
                              >
                                Deactivate
                              </Button>
                            ) : (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 1)}
                              >
                                Activate
                              </Button>
                            )}
                          </Content>
                        </Col>
                        <Col md={16}></Col>
                      </Row>
                    );
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Layout>

        <Modal
          title={`${selectedRow ? "Edit" : "Add"} Time Slot`}
          visible={showFormModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form onSubmit={this.handleSubmit} className="time-slot-form">
                  <Col span={12}>
                    <Form.Item label="Type">
                      {getFieldDecorator("type", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Type!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.type : "",
                      })(
                        <Select
                          placeholder="Select Type"
                          onChange={this.handleChangeType}
                        >
                          <Option value="day">Day</Option>
                          <Option value="date">Date</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  {slotType == "day" ? (
                    <Col span={12}>
                      <Form.Item label="Day">
                        {getFieldDecorator("day", {
                          rules: [
                            {
                              required: true,
                              message: "Please select Day!",
                            },
                          ],
                          initialValue: selectedRow ? selectedRow.day : "",
                        })(
                          <Select placeholder="Select Day">
                            <Option value="Sunday">Sunday</Option>
                            <Option value="Monday">Monday</Option>
                            <Option value="Tuesday">Tuesday</Option>
                            <Option value="Wednesday">Wednesday</Option>
                            <Option value="Thursday">Thursday</Option>
                            <Option value="Friday">Friday</Option>
                            <Option value="Saturday">Saturday</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  ) : null}

                  {slotType == "date" ? (
                    <Col span={12}>
                      <Form.Item label="Date">
                        {getFieldDecorator("day", {
                          rules: [
                            {
                              required: true,
                              message: "Please select Date!",
                            },
                          ],
                          initialValue: selectedRow ? selectedRow.day : "",
                        })(<DatePicker />)}
                      </Form.Item>
                    </Col>
                  ) : null}

                  <Col span={12}>
                    <Form.Item label="Time">
                      {getFieldDecorator("time", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Time!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.time : "",
                      })(
                        <Select placeholder="Select Time">
                          {timeSlot.map((row, index) => {
                            return (
                              <Option value={row} key={index}>
                                {row}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Slots">
                      {getFieldDecorator("slots", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Slots!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.slots : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          { required: true, message: "Please select Status!" },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.status.toString()
                          : "",
                      })(
                        <Select placeholder="Select a Status">
                          <Option value="1">Active</Option>
                          <Option value="0">Inactive</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>
      </div>
    );
  }
}

const TimeSlotForm = Form.create({ name: "time_slot_form" })(TimeSlot);

export default TimeSlotForm;
