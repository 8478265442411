import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { loadSearchDoctor } from "../Scheduler/actions";
import Page from "./Page";

class SearchCard extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const SearchForm = Form.create({ name: "user_card_form" })(SearchCard);

function mapStateToProps(state) {
  return {
    doctorsData: state.scheduler.doctorsData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadSearchDoctor: (data) => dispatch(loadSearchDoctor(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
