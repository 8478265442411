import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import login from "../components/Login/reducers";
import admin from "../components/Appointment/reducers";
import scheduler from "../components/Scheduler/reducers";

const rootReducer = combineReducers({
  form,
  login,
  admin,
  scheduler,
});

export default rootReducer;
