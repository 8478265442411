import React from "react";
import "../../index.scss";
import { Modal, Button, Collapse } from "antd";

export default class ContactModal extends React.Component {
  render() {
    const { visible, isModalShow } = this.props;
    return (
      <Modal
        className="scheduler-submit-modal"
        visible={visible}
        footer={null}
        closable={false}
        centered={true}
        width={400}
        onCancel={isModalShow}
      >
        <div style={{ textAlign: "center" }}>
          <div>
            <h1>CONTACT US</h1>
          </div>
          <div>
            <p>
              You may also contact us at <b>0919-078-5956 (Smart)</b> and
              <b>0961-009-9696 (Smart)</b>
            </p>
          </div>
        </div>
        <Button className="close-button" onClick={() => isModalShow()}>
          CLOSE
        </Button>
      </Modal>
    );
  }
}
