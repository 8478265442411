import React, { Component } from "react";
import {
  Form,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Input,
  Modal,
  Select,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const ButtonGroup = Button.Group;

class EmailTemplate extends Component {
  initialState = {
    showFormModal: false,
    selectedRow: null,
    tableData: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitUpdateEmailTemplate } = this.props;
    const { selectedRow } = this.state;

    this.props.form.validateFields(
      ["subject", "content", "status"],
      async (err, values) => {
        if (!err) {
          const params = {
            ...values,
          };

          params.id = selectedRow.id;
          await onSubmitUpdateEmailTemplate(params).then((response) => {
            if (response.error) {
              Modal.success({
                title: "Unable to update Email Template.",
                content: "",
              });
            } else {
              Modal.success({
                title: "Email Template has been updated successfully",
                content: "",
              });
            }
          });

          this.props.form.resetFields();
          this.handleCloseModal();
        }
      }
    );
  };

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = (row) => {
    this.setState({
      showFormModal: true,
      selectedRow: row,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      selectedRow: null,
    });
  };

  changeStatus = (row, status) => {
    const params = {
      id: row.id,
      status: status,
    };
    this.props.onSubmitUpdateEmailTemplate(params).then((response) => {
      if (response.error) {
        Modal.warning({
          title: "Unable to update status of Email Template.",
          content: "",
        });
      } else {
        Modal.success({
          title: "Email Template status has been updated successfully",
          content: "",
        });
      }
    });
  };

  componentDidMount() {
    this.props.onLoadEmailTemplatesData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.emailTemplatesData !== nextProps.emailTemplatesData) {
      this.setState({ tableData: nextProps.emailTemplatesData });
    }
  }

  render() {
    const { showFormModal, selectedRow, tableData } = this.state;
    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;

    const dataSource = tableData;

    const columns = [
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        sorter: (a, b) => {
          const x = a.subject.toLowerCase();
          const y = b.subject.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Trigger Event",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => {
          const x = a.type.toLowerCase();
          const y = b.type.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (type) => {
          let typeValue = "Creation";
          switch (type) {
            case 1:
              typeValue = "Creation";
              break;

            case 2:
              typeValue = "Confirmation";
              break;

            case 3:
              typeValue = "Cancellation";
              break;

            case 4:
              typeValue = "Completion";
              break;

            default:
              typeValue = "Creation";
              break;
          }
          return <span>{typeValue}</span>;
        },
      },
      {
        title: "Recipient",
        dataIndex: "is_admin",
        key: "is_admin",
        align: "center",
        width: 200,
        filters: [
          { text: "Staff", value: 1 },
          { text: "User", value: 0 },
        ],
        onFilter: (value, record) => record.is_admin === value,
        render: (tags, row) => {
          const is_admin = row.is_admin;
          return is_admin === 1 ? <span>Staff</span> : <span>User</span>;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 200,
        filters: [
          { text: "Active", value: 1 },
          { text: "Inactive", value: 0 },
        ],
        onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          const status = row.status;
          return status === 1 ? <span>Active</span> : <span>Inactive</span>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        render: (data, i) => {
          return (
            <div className="col-action">
              <span>Show/Hide Details</span>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <Layout>
          <Content style={{ padding: 20 }}>
            <Row>
              <Col span={24}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  loading={isLoading}
                  onChange={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }}
                  expandRowByClick
                  expandedRowRender={(record) => {
                    return (
                      <Row>
                        <Col md={3}>
                          <Content className="action-content">
                            <Button
                              className="btn-confirm"
                              onClick={() =>
                                this.handleShowEditFormModal(record)
                              }
                            >
                              Edit
                            </Button>
                            {record.status === 1 ? (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 0)}
                              >
                                Deactivate
                              </Button>
                            ) : (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 1)}
                              >
                                Activate
                              </Button>
                            )}
                          </Content>
                        </Col>
                        <Col md={21}>
                          <Row gutter={32}>
                            <Col className="expand-row-details" md={24}>
                              <h1>Content</h1>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: record.content,
                                }}
                              ></div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Layout>

        <Modal
          title={`${selectedRow ? "Edit" : "Add"} Email Template`}
          visible={showFormModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
          width={900}
          destroyOnClose={true}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form
                  onSubmit={this.handleSubmit}
                  className="email-template-form"
                >
                  <Col span={24}>
                    <Form.Item label="Subject">
                      {getFieldDecorator("subject", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Subject!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.subject : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Content">
                      {getFieldDecorator("content", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter content!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.content : "",
                      })(
                        <ReactQuill
                          value={selectedRow ? selectedRow.content : ""}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Row>
                      <Col span={12}>##firstname## - first name of user</Col>
                      <Col span={12}>##lastname## - last name of user</Col>
                      <Col span={12}>##email## - email of user</Col>
                      <Col span={12}>##mobile## - mobile number of user</Col>
                      <Col span={12}>##book-date## - date of appointment</Col>
                      <Col span={12}>##book-time## - time of appointment</Col>
                      <Col span={12}>##tracking-number## - tracking number</Col>
                      <Col span={12}>##doctor## - Referring doctor</Col>
                      {/* <Col span={12}>##special-note## - Special notes</Col> */}
                      <Col span={12}>
                        ##consultation-mode## - preferred consultation mode
                      </Col>
                      <Col span={12}>
                        ##contact-mobile## - contact mobile of program
                      </Col>
                      <Col span={12}>
                        ##contact-email## - contact email of program
                      </Col>
                      <Col span={12}>##program-name## - program name</Col>
                      <Col span={12}>
                        ##staff-firstname## - first name of staff assigned
                      </Col>
                      <Col span={12}>
                        ##staff-lastname## - last name of staff assigned
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          { required: true, message: "Please select Status!" },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.status.toString()
                          : "",
                      })(
                        <Select placeholder="Select a Status">
                          <Option value="1">Active</Option>
                          <Option value="0">Inactive</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>
      </div>
    );
  }
}

const EmailTemplateForm = Form.create({ name: "day_off_form" })(EmailTemplate);

export default EmailTemplateForm;
