import React from "react";
import "./index.scss";

const MaintenancePage = () => (
  <div className="not-found">
    <h1>This website is not available</h1>
    <div>
      <p>Please consult your Domain Administrator to resolve this issue.</p>
    </div>
  </div>
);

export default MaintenancePage;
