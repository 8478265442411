import React, { Component } from 'react';

import Layout from 'antd/lib/layout';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';

import ImageUploader from "../../../shared/ImageUploader";

const { Content } = Layout;

const IMAGE_DOMAIN = process.env.REACT_APP_IMAGE_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            tableData: [],
        }
        this.fileUploader = React.createRef();
    }

    componentDidMount() {
        this.props.onLoadDynamicImagesData(CLIENT_ID);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.dynamicImagesData !== nextProps.dynamicImagesData) {
            this.setState({ tableData: nextProps.dynamicImagesData });
        }
      }

    handleUpload = async () => {
        this.setState({
            uploading: true
        })

        await this.fileUploader.current.handleUploadRequest();

        this.setState({
            uploading: false,
        })

        window.location.reload();
    }

    render(){
        const { uploading, tableData, hasImage } = this.state;
        const { isLoading, match } = this.props

        const columns = [
            {
                title: "Page",
                dataIndex: "page",
                key: "page",
                width: '25%'
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                width: '25%'
            },
            {
              title: "Image",
              dataIndex: "image",
              key: "image",
              width: '30%',
                render: (url, data) => {
                    return (
                        <img 
                            src={`${IMAGE_DOMAIN}/${data.path}/${data.filename}.png`} 
                            alt='Find Dictor Background'
                            width='auto'
                            height='100px'
                        />
                    );
                },
                },
            {
                title: "",
                dataIndex: "",
                key: "x",
                width: '20%',
                render: (a, data) => {
                    return (
                        <div 
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                <ImageUploader
                                    match={match}
                                    ref={this.fileUploader}
                                    fileFolder={`${data.path}`}
                                    buttonDesc=""
                                    imageUrl={`${data.path}/${data.filename}.png`} 
                                    fileName={data.filename}
                                />
                            </div>
                            <Button 
                                type='primary'
                                onClick={() => this.handleUpload()}
                                style={{
                                    width: 120
                                }}
                                loading={uploading}
                            >
                                <span style={{ color: '#fff' }}>{data.image ? "CHANGE" : "UPLOAD"}</span>
                            </Button>
                        </div>
                    );
                },
              },
        ];

        return(
            <Layout>
                <Content style={{ padding: 20 }}>
                <Row>
                    <Col>
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            onChange={() => {
                                document.body.scrollTop = 0;
                                document.documentElement.scrollTop = 0;
                            }}
                            loading={isLoading}
                        />
                    </Col>
                </Row>
                </Content>
        </Layout>
        );
    }
} 

export default Images;