import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import {
  loadSlotsData,
  submitScheduleAppointment,
  loadDayOffsData,
  loadDoctor,
} from "./actions";
import Page from "./Page";

class Scheduler extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const SchedulerForm = Form.create({ name: "scheduler_form" })(Scheduler);

function mapStateToProps(state) {
  return {
    isLoading: state.scheduler.isLoading,
    slotsData: state.scheduler.slotsData,
    dayOffsData: state.scheduler.dayOffsData,
    doctorData: state.scheduler.doctorData,
    isProcessing: state.scheduler.isProcessing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoadSlotsData: (data) => dispatch(loadSlotsData(data)),
    onLoadDoctor: (data) => dispatch(loadDoctor(data)),
    onLoadDayOffsData: () => dispatch(loadDayOffsData()),
    onSubmitScheduleAppointment: (data) =>
      dispatch(submitScheduleAppointment(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerForm);
