import Axios from "axios";
import CustomAxios from "../../../utils/axios";

const POST_LOGIN_DATA = "POST_LOGIN_DATA";
const POST_LOGIN_DATA_FULFILLED = "POST_LOGIN_DATA_FULFILLED";
const POST_LOGIN_DATA_REJECTED = "POST_LOGIN_DATA_REJECTED";

const API_URL = process.env.REACT_APP_API_URL;

export const submitLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: POST_LOGIN_DATA,
      payload: {},
    });
    return Axios.post(`${API_URL}/login`, { ...data })
      .then(function(response) {
        dispatch({
          type: POST_LOGIN_DATA_FULFILLED,
          payload: response.data,
        });
        localStorage.setItem("TOKEN", response.data.success.token);
        localStorage.setItem("USER", JSON.stringify(response.data.user));

        window.location.href = "/admin?tab=Appointments";
      })
      .catch(function(error) {
        dispatch({
          type: POST_LOGIN_DATA_REJECTED,
          payload: error,
        });
      });
  };
};

export const submitForgot = (data) => {
  return (dispatch) => {
    return CustomAxios.post(`/forgot`, dispatch, "POST_FORGOT", data).then(
      (response) => {
        return response;
      }
    );
  };
};
