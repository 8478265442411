import React from "react";
import Logo from "../../../assets/images/logo.png";

export default function cLogo(props) {
  return (
    <img
      src={Logo}
      loading="lazy"
      alt="professional insights margeting services"
      className="mims-brand"
      onClick={() => props.history.push("/")}
      style={{ cursor: "pointer" }}
    />
  );
}
