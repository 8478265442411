import React from "react";
import "../../index.scss";
import { Modal, Button } from "antd";
import "boxicons";
import Logo from "../../../../assets/images/logo-only.png";

export default class PopupModal extends React.Component {
  render() {
    const { showPopup, handleModalClose, handleAgree, handleOpenLink } = this.props;
    return (
      <Modal
        className="popup-modal"
        visible={showPopup}
        footer={null}
        closable={false}
        centered={true}
        width={1000}
        onCancel={handleModalClose}
        bodyStyle={{
          maxHeight: "93%",
          position: "relative",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "auto"
          }}
        >
          <div
            style={{
              textAlign: "justify",
              maxHeight: "100%",
              padding: "0 10px",
            }}
          >
            <div style={{ "textAlign": "center", "marginBottom": "30px" }}>
              <h3>
              <img src={Logo} alt="logo" height="100px" /><br />
              <span style={{ "fontSize": "2rem", "color":  "#7430BD"}}>Welcome to EasyReach Teleconsultation Site.</span><br />
              Please choose how you want to proceed with your consultation. 
              </h3>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button className="popup-close-button" onClick={handleOpenLink}>
                <box-icon name='list-check' color="#ffffff" size="lg"></box-icon><br />
                I want to fill-out medical assessment questionnaire
              </Button>
              <Button className="popup-agree-button" onClick={handleAgree}>
                <box-icon name='support' color="#fb4570" size="lg"></box-icon><br />
                I want to book an online consultation with a doctor
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
