import React, { Component } from "react";
import {
  Form,
  Layout,
  Row,
  Col,
  Typography,
  Card,
  Input,
  Button,
  Avatar,
  Skeleton,
} from "antd";
import "./index.scss";

import Header from "../shared/SchedulerHeader";
import Footer from "../shared/AdminFooter";
import PopupModal from "./components/PopupModal";

class Search extends Component {
  initialState = {
    isSearch: false,
    isLoading: false,
    show: 10,
    doctorsData: [],
    popupModalVisible: true,
    agree: false,
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handlePopupModalClose = () => {
    this.setState({ popupModalVisible: false });
    let modalInterval = setInterval(() => {
      if (!this.state.agree) {
        this.setState({ popupModalVisible: true });
      }

      clearInterval(modalInterval);
    }, 1000);
  };

  handlePopupAgree = () => {
    this.setState({ agree: true });
    this.handlePopupModalClose();
  };

  handleOpenLink = () => {
    window.open("https://docs.google.com/forms/d/e/1FAIpQLSceygiN4pm3u7dJJtmNYMUMcJKBo3sTYB6uM0OrpmGB9Mk0mw/viewform");
    this.handlePopupModalClose();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.onLoadSearchDoctor(values);
        this.setState({
          isSearch: true,
        });
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 2000);
        // window.scrollTo(0, 0);
        // setTimeout(() => {
        //   this.setState({
        //     isSearch: true,
        //   });
        // }, 2000);
        // setTimeout(() => {
        //   this.setState({
        //     isLoading: false,
        //   });
        // }, 2000);
      }
    });
  };

  showMore = () => {
    this.setState({
      isLoading: true,
    });
    setTimeout(() => {
      this.setState({
        show: this.state.show + 10,
        isLoading: false,
      });
    }, 2000);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.doctorsData !== nextProps.doctorsData) {
      this.setState({ doctorsData: nextProps.doctorsData });
    }
  }

  render() {
    const { isSearch, isLoading, show, doctorsData, popupModalVisible } = this.state;
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <React.Fragment>
        <Layout
          className={`${isSearch ? "search-result-layout" : "search-layout"} fade-in`}
          style={{
            backgroundImage: isSearch ? '' : `url(${process.env.REACT_APP_IMAGE_DOMAIN}/dynamic/images/findDoctorImage.png)`
          }}
        >
          <Layout.Content className="search-content">
            <Header {...this.props} />
            {!isSearch && (
              <>
                <Typography.Title className="page-title">
                  Thank you for visiting our site. 
                </Typography.Title>
                <Typography.Paragraph className="page-description">
                  You may search by doctor’s name or by specialization below or simply click Search to book an appointment.
                </Typography.Paragraph>
              </>
            )}

            <Card
              className={`search-card ${isSearch &&
                "search-results-card fade-in"}`}
            >
              {isSearch && (
                <>
                  <Typography.Title className="page-title">
                    Search Result
                  </Typography.Title>
                  <Typography.Paragraph className="page-description">
                    {`${10} Doctors Found`}
                  </Typography.Paragraph>
                </>
              )}
              <Form onSubmit={this.handleSubmit} colon={false}>
                <Row type="flex" justify="space-between" gutter={30}>
                  <Col xs={24} md={12}>
                    <Form.Item label="MD First Name">
                      {getFieldDecorator("firstname")(
                        <Input placeholder="MD First Name" autoFocus />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="MD Last Name">
                      {getFieldDecorator("lastname")(
                        <Input placeholder="MD Last Name" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="Specialization">
                      {getFieldDecorator("specialization")(
                        <Input placeholder="Specialization" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label="City">
                      {getFieldDecorator("city")(<Input placeholder="City" />)}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={15}>
                    {isSearch ? (
                      <Typography.Paragraph className="tip">
                        Tip: Try to remember your doctor's full name if you
                        can't find it in the results.
                      </Typography.Paragraph>
                    ) : (
                      <Typography.Paragraph className="tip">
                        Tip: You can start by looking for your family's
                        preferred or your child's pediatrician
                      </Typography.Paragraph>
                    )}
                  </Col>
                  <Col xs={24} md={9} align="right">
                    <Button
                      className="btn-primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>

            {isSearch && (
              <Row
                type="flex"
                justify="center"
                className="search-result-item-container"
              >
                <Col xs={20} lg={18} md={18}>
                  <Row
                    type="flex"
                    justify="center"
                    className="results"
                    gutter={10}
                  >
                    {doctorsData.slice(0, show).map((row, key) => (
                      <Col xs={24} md={12} lg={8} key={key}>
                        <Card
                          style={{
                            width: "100%",
                            marginTop: 16,
                            cursor: "pointer",
                          }}
                          className="doctor-card"
                          onClick={() =>
                            this.props.history.push(
                              `/schedule?doctor=${row.firstname}.${row.lastname}&id=${row.id}`
                            )
                          }
                        >
                          <Skeleton loading={isLoading} avatar active>
                            <Row>
                              <Col span={8}>
                                {/* <object
                                  data="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                  type="image/png"
                                  class="ant-avatar ant-avatar-circle"
                                  style={{ height: "50px", width: "50px" }}
                                > */}
                                <Avatar
                                  src={`${
                                    row &&
                                    row.detail &&
                                    row.detail &&
                                    row.detail.image !== null
                                      ? process.env.REACT_APP_S3_BUCKET +
                                        row.detail.image
                                      : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                  }`}
                                  size={70}
                                />
                                {/* </object> */}
                              </Col>
                              <Col span={16}>
                                <div className="doctor-card__name">
                                  {row.firstname} {row.lastname}
                                </div>
                                <div className="doctor-card__specialization">
                                  {row.detail.specialization}
                                </div>
                                <div className="doctor-card__hospital">
                                  {row.detail.hospital}
                                </div>
                                <div className="doctor-card__location">
                                  {row.detail.location}
                                </div>
                              </Col>
                            </Row>
                            {/* <Card.Meta
                              avatar={
                                <Avatar
                                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                  size={50}
                                />
                              }
                              title={`${row.firstname} ${row.lastname}`}
                              description="This is the description"
                            /> */}
                          </Skeleton>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {/* {isSearch && (
                  <Col span={24} align="center">
                    <Button onClick={this.showMore} className="show-more">
                      Show More
                    </Button>
                  </Col>
                )} */}
              </Row>
            )}
          </Layout.Content>
        </Layout>
        <Footer isSearch={isSearch} />

        <PopupModal
            showPopup={popupModalVisible}
            handleModalClose={this.handlePopupModalClose}
            handleAgree={this.handlePopupAgree}
            handleOpenLink={this.handleOpenLink}
          />
      </React.Fragment>
    );
  }
}

export default Form.create({ name: "search" })(Search);
