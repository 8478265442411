import React, { Component } from "react";
import {
  Form,
  Button,
  Layout,
  Row,
  Col,
  Badge,
  Input,
  Modal,
  Select,
  DatePicker,
  Calendar,
} from "antd";
import moment from "moment";

const { Content } = Layout;
const { Option } = Select;

class Appointment extends Component {
  initialState = {
    showFormModal: false,
    selectedRow: null,
    tableData: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleShowFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleShowEditFormModal = (row) => {
    this.setState({
      showFormModal: true,
      selectedRow: row,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showFormModal: false,
      selectedRow: null,
    });
  };

  getListData = (value) => {
    const calendarDate = moment(value).format("YYYY-MM-DD");

    let appointments = this.state.tableData.filter((row) => {
      if (row.status === 0 || row.status === 2) {
        return false;
      }

      if (row.appointment_date === calendarDate) {
        return true;
      }

      return false;
    });

    return appointments;
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map((item, index) => {
            if (!item.staff) {
              return "";
            }

            if (item.status != 1 && item.status != 3) {
              return "";
            }

            return (
            <li key={index}>
              <Badge
                status="success"
                text={`${item.appointment_time} - ${item.staff.firstname} ${item.staff.lastname}`}
              />
            </li>
            )
          }
        )}
      </ul>
    );
  };

  componentDidMount() {
    this.props.onLoadAppointmentsData();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.appointmentsData !== nextProps.appointmentsData) {
      this.setState({ tableData: nextProps.appointmentsData });
    }
  }

  render() {
    const { showFormModal, selectedRow, tableData } = this.state;
    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;

    return (
      <div>
        <Layout>
          <Content style={{ padding: 20 }}>
            <Row>
              <Col span={24}>
                <Calendar
                  onPanelChange={this.onPanelChange}
                  dateCellRender={this.dateCellRender}
                />
              </Col>
            </Row>
          </Content>
        </Layout>

        <Modal
          title={`${selectedRow ? "Edit" : "Add"} Day Off`}
          visible={showFormModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form onSubmit={this.handleSubmit} className="appointment-form">
                  <Col span={12}>
                    <Form.Item label="Name">
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Name!",
                          },
                        ],
                        initialValue: selectedRow ? selectedRow.name : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Date">
                      {getFieldDecorator("date", {
                        rules: [
                          {
                            required: true,
                            message: "Please select Date!",
                          },
                        ],
                        initialValue: selectedRow
                          ? moment(selectedRow.date)
                          : "",
                      })(<DatePicker />)}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Status">
                      {getFieldDecorator("status", {
                        rules: [
                          { required: true, message: "Please select Status!" },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.status.toString()
                          : "",
                      })(
                        <Select placeholder="Select a Status">
                          <Option value="1">Active</Option>
                          <Option value="0">Inactive</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>
      </div>
    );
  }
}

const AppointmentForm = Form.create({ name: "day_off_form" })(Appointment);

export default AppointmentForm;
