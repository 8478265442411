import Axios from "../../../utils/axios";

// APPOINTMENTS
export const loadAppointmentsData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/appointments", dispatch, "GET_APPOINTMENTS", data);
  };
};

export const submitUpdateAppointment = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/appointment/${data.id}`,
      dispatch,
      "POST_APPOINTMENTS",
      data
    ).then((response) => {
      dispatch(loadAppointmentsData());
      return response;
    });
  };
};

export const submitUpdateAppointmentStatus = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/appointment-status/${data.id}`,
      dispatch,
      "POST_APPOINTMENTS",
      data
    ).then((response) => {
      dispatch(loadAppointmentsData());
      return response;
    });
  };
};

// TIME SLOTS
export const loadTimeSlotsData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/time-slots", dispatch, "GET_TIME_SLOTS", data);
  };
};

export const submitAddTimeSlot = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/time-slot`,
      dispatch,
      "POST_TIME_SLOTS",
      data
    ).then((response) => {
      dispatch(loadTimeSlotsData());
      return response;
    });
  };
};

export const submitUpdateTimeSlot = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/time-slot/${data.id}`,
      dispatch,
      "POST_TIME_SLOTS",
      data
    ).then((response) => {
      dispatch(loadTimeSlotsData());
      return response;
    });
  };
};

// DAY OFFS
export const loadDayOffsData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/day-offs", dispatch, "GET_DAY_OFFS", data);
  };
};

export const submitAddDayOff = (data) => {
  return (dispatch) => {
    return Axios.post(`/admin/day-off`, dispatch, "POST_DAY_OFFS", data).then(
      (response) => {
        dispatch(loadDayOffsData());
        return response;
      }
    );
  };
};

export const submitUpdateDayOff = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/day-off/${data.id}`,
      dispatch,
      "POST_DAY_OFFS",
      data
    ).then((response) => {
      dispatch(loadDayOffsData());
      return response;
    });
  };
};

// EMAIL TEMPLATES
export const loadEmailTemplatesData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/email-templates", dispatch, "GET_EMAIL_TEMPLATES", data);
  };
};

export const submitUpdateEmailTemplate = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/email-template/${data.id}`,
      dispatch,
      "POST_EMAIL_TEMPLATES",
      data
    ).then((response) => {
      dispatch(loadEmailTemplatesData());
      return response;
    });
  };
};

// USERS
export const loadUsersData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/users", dispatch, "GET_USERS", data);
  };
};

export const loadAdminUsersData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/admin-users", dispatch, "GET_ADMIN_USERS", data);
  };
};

export const loadDoctorUsersData = (data) => {
  return (dispatch) => {
    Axios.get("/admin/doctor-users", dispatch, "GET_DOCTOR_USERS", data);
  };
};

export const submitAddUser = (data) => {
  return (dispatch) => {
    return Axios.post(`/admin/user`, dispatch, "POST_USERS", data).then(
      (response) => {
        dispatch(loadUsersData());
        return response;
      }
    );
  };
};

export const submitAddDoctor = (data) => {
  return (dispatch) => {
    return Axios.post(`/admin/user-doctor`, dispatch, "POST_USERS", data).then(
      (response) => {
        dispatch(loadDoctorUsersData());
        return response;
      }
    );
  };
};

export const submitUpdateUser = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/user/${data.id}`,
      dispatch,
      "POST_USERS",
      data
    ).then((response) => {
      dispatch(loadUsersData());
      return response;
    });
  };
};

export const submitUpdateDoctor = (data) => {
  return (dispatch) => {
    return Axios.post(
      `/admin/user-doctor/${data.id}`,
      dispatch,
      "POST_USERS",
      data
    ).then((response) => {
      dispatch(loadDoctorUsersData());
      return response;
    });
  };
};

export const loadDynamicImagesData = (clientId) => {
  return (dispatch) => {
    Axios.get(
      `/admin/dynamic-images/${clientId}`,
      dispatch,
      "GET_DYNAMICIMAGES"
    );
  };
};
