import React from "react";
import "../../index.scss";
import { Modal, Button } from "antd";
import "boxicons";
import Logo from "../../../../assets/images/logo-only.png";

export default class PopupModal extends React.Component {
  render() {
    const {
      showPopup,
      handleModalClose,
      handleAgree,
      handleOpenLink,
    } = this.props;
    return (
      <Modal
        className="popup-modal"
        visible={showPopup}
        footer={null}
        closable={false}
        centered={true}
        width={1000}
        onCancel={handleModalClose}
        bodyStyle={{
          maxHeight: "93%",
          position: "relative",
        }}
      >
        <div
          style={{
            textAlign: "left",
            maxHeight: "100%",
            padding: "0 10px",
            with: "100%",
          }}
        >
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <img src={Logo} height="100px" />
            <div className="modal-subheader">
              Welcome to EasyReach Teleconsultation Site.
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              marginBottom: "30px",
              maxHeight: "65vh",
              overflowY: "scroll",
              padding: "10px 20px",
            }}
          >
            <div className="modal-header">TERMS OF USE</div>
            <div className="modal-subheader">1. Introduction</div>
            <div className="modal-paragraph">
              <p>
                This website is operated by EasyReach through Professional
                Insights Marketing Services. (hereinafter also “EasyReach”,
                “we”, “us” or “our”).
              </p>
              <p>
                This website is solely for the appointment portal. Certain
                contents are intended for particular audiences including
                EasyReach´s employees and its partners. By using this website,
                you declare that you understand its contents, as well as the
                risks of its use. You agree to provide limited personal
                information for the purpose of registration and to book online
                consultation appointments with EasyReach. You acknowledge that
                the consent is given voluntarily and you may withdraw from this
                program anytime. Before joining, you understand and confirm that
                checklist to be used and other materials to be provided are
                intended solely for me and for my exclusive use.
              </p>
            </div>
            <div className="modal-subheader">2. Exclusion of Liability</div>
            <div className="modal-paragraph">
              <p>
                EasyReach has compiled the content of this website carefully and
                in accordance with its current state of knowledge
              </p>
              <p>
                We are determined to provide the most effective and most
                suitable services by continuously updating the details on this
                website. However, in particular, please be aware that access to
                and use of this website and of any associated sites or sites
                connected via links take place at the user’s own risk. With
                this, EasyReach disclaims all warranties, expressed or implied,
                including merchantability or fitness warranties for any
                particular purpose. We accept no liability for loss, damage or
                compensation claims arising from missing or incorrect details.
              </p>
            </div>
            <div className="modal-subheader">3. Legal Notes</div>
            <div className="modal-paragraph">
              <p>
                All rights to the website or arising from the same shall remain
                with EasyReach.
              </p>
              <p>
                We may modify this website and/or discontinue its operation at
                any time without prior announcement, either wholly or in parts,
                at its own discretion and without accepting liability.
              </p>
              <p>
                Unless otherwise indicated, all details, product names,
                documents and presentations published on our website, whether or
                not appearing with the EasyReach ´s trademark symbol, are under
                the sole ownership of EasyReach, its affiliates or its
                licensors. Unless written consent has been obtained from
                EasyReach, no permission is given to import published and
                copyrighted content from our website to other programs or
                websites or to use such content in any other way.
              </p>
              <p>This website is intended to comply with Philippine law.</p>
            </div>
            <div className="modal-subheader">4. Personal Data</div>
            <div className="modal-paragraph">
              <p>
                EasyReach is particularly concerned to ensure the protection of
                your personal data. You will find information on the use of
                personal data in the Privacy Statement.
              </p>
            </div>
            <div className="modal-subheader">5. Other Contents</div>
            <div className="modal-paragraph">
              <p>
                This website site may contain general and specific information
                regarding mechanics and overview of the program. Such
                information is provided for informational and educational
                purposes for its target customers.
              </p>
            </div>

            <div className="modal-header">PRIVACY NOTICE</div>
            <div className="modal-subheader">1. Introduction</div>
            <div className="modal-paragraph">
              <p>
                Thank you for your interest in this website. EasyReach (“we”,
                “us” or “our”) values and protects your privacy rights.
              </p>
              <p>
                This Privacy Notice ("Notice") describes what Personal Data is
                collected from users ("you" or "the user") through EasyReacH
                ("the website"), and through the consultation that you will
                avail of using the website. This Notice also explains how we use
                such Personal Data.
              </p>
              <p>
                This Notice also describes your rights and how you can execute
                them.
              </p>
              <p>
                Personal Data is any personal information through which you can
                be directly or indirectly identified, e.g. your email address,
                your name, your contact number, or other information needed for
                the consultation.
              </p>
              <p>
                While this website is operated by EasyReach, any information you
                provide in this website shall be collected and processed by its
                partners Ipadama 360 Inc. and Professional Insights Marketing
                Services (PIMS).
              </p>
            </div>
            <div className="modal-subheader">
              2. What Personal Data do we collect and for which purposes do we
              use it?
            </div>
            <div className="modal-paragraph">
              <table style={{ width: "100%" }}>
                <thead>
                  <th>Personal Data</th>
                  <th>Purpose/s for Processing</th>
                </thead>
                <tbody>
                  <tr>
                    <td>Name of Patient</td>
                    <td>
                      For booking appointments and to properly address the users
                      during the consultation
                    </td>
                  </tr>
                  <tr>
                    <td>Patient’s email address</td>
                    <td>
                      Where appointment confirmation and details of consultation
                      will be sent
                      <br />
                      Where our partners for this program send their marketing
                      materials
                    </td>
                  </tr>
                  <tr>
                    <td>Name of Patient</td>
                    <td>
                      For booking appointments and to properly address the users
                      during the consultation
                    </td>
                  </tr>
                  <tr>
                    <td>Patient’s contact numbers</td>
                    <td>
                      Where you may be reached to conduct the consultation
                    </td>
                  </tr>
                  <tr>
                    <td>Patient’s medical history</td>
                    <td>
                      Will be used as a reference to properly conduct the
                      consultation
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-subheader">3. Data Processing</div>
            <div className="modal-paragraph">
              <p>
                Processing is hereby understood to include any operation or any
                set of operations performed upon personal information including,
                but not limited to, the collection, recording, organization,
                storage, sharing, updating or modification, retrieval,
                consultation, use, consolidation, blocking, erasure or
                destruction of data. Processing would include both manual and
                automated handling of personal information and storage and data
                transfers using various means including but limited to physical
                methods as well as electronic via information and communications
                systems employed by EasyReach and its partners. The information
                that you will share will be used for the purpose indicated
                above. This information shall be kept confidential at all times
                and only authorized personnel of EasyReach , its partner Ipadama
                360 Inc. and PIMS. will have access to your information. We
                implement reasonable and appropriate technical, physical, and
                organizational measures to prevent loss, destruction, misuse or
                alteration of your personal information.
              </p>
            </div>
            <div className="modal-subheader">
              4. Sharing personal data with third parties
            </div>
            <div className="modal-paragraph">
              <p>
                We will share your Personal Data to third parties when we engage
                such third parties that use Personal Data to provide the
                services that you availed of or requested, on our behalf and
                according to our instructions. We also share Personal Data with
                our business partners Ipadama 360 Inc. and PIMS to fulfill the
                purpose/s, for which you have agreed to have your Personal Data
                processed, in accordance with this Notice.
              </p>
            </div>
            <div className="modal-subheader">5. Data retention</div>
            <div className="modal-paragraph">
              <p>
                We will retain Personal Data as required by legal obligation, or
                for the period of time that is required to provide the service/s
                you have requested or availed of, or as long as required for the
                specific business purpose or purposes for which it was
                collected.
              </p>
            </div>
            <div className="modal-subheader">
              6. Right to access, review, correct and object
            </div>
            <div className="modal-paragraph">
              <p>
                You are entitled to review and rectify any incorrect or outdated
                Personal Data we store, and you may withdraw your consent to the
                use of your data at any time, affecting all future use. You can
                also request a copy of information we store about you. All you
                need to do is to send an e- mail to:{" "}
                <span style={{ color: "#7430bd" }}>
                  appointment@easyreach.ph
                </span>
                Furthermore, you have the right to lodge a complaint with the
                competent data protection authority.
              </p>
            </div>
            <div className="modal-subheader">7. Security</div>
            <div className="modal-paragraph">
              <p>
                We follow industry standard practices to protect the
                confidentiality, integrity, and availability of data, always
                complying with applicable laws e.g. by access controls. We
                strive to update our security measures on a regular basis to
                keep track of new industry standards. Our data protection
                measures are continually audited and updated, reflecting the
                state of the art and the technical developments.
              </p>
            </div>
            <div className="modal-subheader">
              8. Cookies used on this website
            </div>
            <div className="modal-paragraph">
              <p>
                The website uses cookies to help manage your preferences.
                Cookies also ensure that certain features function as intended
                and in a user-friendly manner. You may opt to block or remove
                cookies but doing so might limit the performance of certain
                sections of the website.
              </p>
            </div>
            <div className="modal-subheader">9. Contact</div>
            <div className="modal-paragraph">
              <p>
                By providing your personal information, registering and availing
                of the services provided by EasyReach, you hereby warrant that
                you understand your rights and obligations under the Data
                Privacy Act and its implementing rules and regulations and that
                you shall hold EasyReach and its partners Ipadama 360 Inc. and
                PIMS. and assigns free and harmless from any claim for liability
                or damages with regard to the processing and collection of your
                Personal Data. If you have questions or suggestions, please feel
                free to contact our local Data Privacy Officer through this
                email:{" "}
                <span style={{ color: "#7430bd" }}>
                  jaguiao@profinsights.biz
                </span>
              </p>
            </div>
            <div className="modal-subheader">CONTACT US</div>
            <div className="modal-paragraph">
              <p>
                You may also contact us{" "}
                <span style={{ color: "#7430bd" }}>
                  at <b>0919-078-5956 (Smart)</b> and&nbsp;
                  <b>0961-009-9696 (Smart)</b>
                </span>
              </p>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button className="popup-close-button" onClick={handleModalClose}>
              {/* <box-icon name='list-check' color="#ffffff" size="lg"></box-icon><br /> */}
              Cancel
            </Button>
            <Button className="popup-agree-button" onClick={handleAgree}>
              {/* <box-icon name='support' color="#fb4570" size="lg"></box-icon><br /> */}
              I Agree
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
