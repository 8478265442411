import React from "react";
import "../../index.scss";
import { Modal, Button } from "antd";

export default class TermsModal extends React.Component {
  render() {
    const { visible, isModalShow } = this.props;
    return (
      <Modal
        className="scheduler-submit-modal"
        visible={visible}
        footer={null}
        closable={false}
        centered={true}
        width={800}
        onCancel={isModalShow}
        bodyStyle={{
          maxHeight: "90vh",
          position: "relative",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              textAlign: "justify",
              overflowY: "scroll",
              maxHeight: "95%",
              padding: "0 10px",
            }}
          >
            <div>
              <h1>TERMS OF USE</h1>
            </div>
            <div>
              <h3>1. Introduction</h3>
              <p>
                This website is operated by EasyReach through Professional Insights Marketing Services. (hereinafter also “EasyReach”, “we”, “us” or “our”).
              </p>
              <p>
                This website is solely for the appointment portal. Certain contents are intended for particular audiences including EasyReach´s employees and its partners. By using this website, you declare that you understand its contents, as well as the risks of its use. You agree to provide limited personal information for the purpose of registration and to book online consultation appointments with EasyReach. You acknowledge that the consent is given voluntarily and you may withdraw from this program anytime. Before joining, you understand and confirm that checklist to be used and other materials to be provided are intended solely for me and for my exclusive use.
              </p>

              <h3>2. Exclusion of Liability</h3>
              <p>
                EasyReach has compiled the content of this website carefully and in accordance with its current state of knowledge
              </p>
              <p>
                We are determined to provide the most effective and most suitable services by continuously updating the details on this website. However, in particular, please be aware that access to and use of this website and of any associated sites or sites connected via links take place at the user’s own risk. With this, EasyReach disclaims all warranties, expressed or implied, including merchantability or fitness warranties for any particular purpose. We accept no liability for loss, damage or compensation claims arising from missing or incorrect details.
              </p>

              <h3>3. Legal Notes</h3>
              <p>
                All rights to the website or arising from the same shall remain with EasyReach.
              </p>
              <p>
                We may modify this website and/or discontinue its operation at any time without prior announcement, either wholly or in parts, at its own discretion and without accepting liability.
              </p>
              <p>
                Unless otherwise indicated, all details, product names, documents and presentations published on our website, whether or not appearing with the EasyReach ´s trademark symbol, are under the sole ownership of EasyReach, its affiliates or its licensors. Unless written consent has been obtained from EasyReach, no permission is given to import published and copyrighted content from our website to other programs or websites or to use such content in any other way.
              </p>
              <p>This website is intended to comply with Philippine law.</p>

              <h3>4. Personal Data</h3>
              <p>
                EasyReach is particularly concerned to ensure the protection of your personal data. You will find information on the use of personal data in the Privacy Statement.
              </p>

              <h3>5. Other Contents</h3>

              <p>
                This website site may contain general and specific information regarding mechanics and overview of the program. Such information is provided for informational and educational purposes for its target customers.
              </p>
            </div>
          </div>
          <Button className="close-button" onClick={() => isModalShow()}>
            CLOSE
          </Button>
        </div>
      </Modal>
    );
  }
}
