import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Form } from "antd";
import { submitReset } from "./actions";
import Page from "./Page";

class Login extends Component {
  render() {
    return <Page {...this.props} />;
  }
}

const LoginForm = Form.create({ name: "login_form" })(Login);

function mapStateToProps(state) {
  return {
    isLoading: state.login.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitReset: (data) => dispatch(submitReset(data)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
