import CustomAxios from "../../../utils/axios";

export const submitReset = (data) => {
  return (dispatch) => {
    return CustomAxios.post(`/reset`, dispatch, "POST_RESET", data).then(
      (response) => {
        return response;
      }
    );
  };
};
