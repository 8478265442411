import Axios from "../../../utils/axios";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export const loadSlotsData = (data) => {
  return (dispatch) => {
    Axios.get(
      `/available-time-slots/${CLIENT_ID}/${data}`,
      dispatch,
      "GET_SLOTS"
    );
  };
};

export const loadSearchDoctor = (data) => {
  return (dispatch) => {
    Axios.get(
      `/search-doctor/${CLIENT_ID}`,
      dispatch,
      "GET_SEARCH_DOCTOR",
      data
    );
  };
};

export const loadDoctor = (data) => {
  return (dispatch) => {
    Axios.get(`/doctor/${CLIENT_ID}/${data.id}`, dispatch, "GET_DOCTOR");
  };
};

export const loadDayOffsData = () => {
  return (dispatch) => {
    Axios.get(`/available-day-offs/${CLIENT_ID}`, dispatch, "GET_DAY_OFFS");
  };
};

export const submitScheduleAppointment = (data) => {
  return (dispatch) => {
    return Axios.post(`/appointment`, dispatch, "POST_APPOINTMENT", data).then(
      (response) => {
        dispatch(loadSlotsData());
        return response;
      }
    );
  };
};
