const initialData = {
  isLoading: false,
  isProcessing: false,
  slotsData: [],
  dayOffsData: [],
  doctorsData: [],
  doctorData: [],
};

export default function admin(state = initialData, action) {
  switch (action.type) {
    case "GET_SEARCH_DOCTOR":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SEARCH_DOCTOR_SUCCESS":
      return {
        ...state,
        doctorsData: action.payload,
        isLoading: false,
      };
    case "GET_SEARCH_DOCTOR_FAILED":
      return {
        ...state,
        doctorsData: [],
        isLoading: false,
      };

    case "GET_DOCTOR":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DOCTOR_SUCCESS":
      return {
        ...state,
        doctorData: action.payload,
        isLoading: false,
      };
    case "GET_DOCTOR_FAILED":
      return {
        ...state,
        doctorData: [],
        isLoading: false,
      };

    // TIME SLOTS -----------------------------------------
    case "GET_SLOTS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_SLOTS_SUCCESS":
      return {
        ...state,
        slotsData: action.payload,
        isLoading: false,
      };
    case "GET_SLOTS_FAILED":
      return {
        ...state,
        slotsData: [],
        isLoading: false,
      };

    case "GET_DAY_OFFS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_DAY_OFFS_SUCCESS":
      return {
        ...state,
        dayOffsData: action.payload,
        isLoading: false,
      };
    case "GET_DAY_OFFS_FAILED":
      return {
        ...state,
        dayOffsData: [],
        isLoading: false,
      };

    case "POST_APPOINTMENT_REQUEST":
      return {
        ...state,
        isProcessing: true,
      };
    case "POST_APPOINTMENT_SUCCESS":
      return {
        ...state,
        isProcessing: false,
      };
    case "POST_APPOINTMENT_FAILED":
      return {
        ...state,
        isProcessing: false,
      };

    default:
      return {
        ...state,
      };
  }
}
