const initialData = {
  loggingIn: false,
  loggedIn: false,
  loginRejected: false,
  loggedData: {
    user: {
      username: "",
      email: "",
    },
  },
  isLoading: false,
};

export default function login(state = initialData, action) {
  switch (action.type) {
    case "GET_FORGOT_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_FORGOT_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_FORGOT_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "POST_LOGIN_REQUEST":
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginRejected: false,
      };
    case "POST_LOGIN_DATA_FULFILLED":
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loggedData: action.payload,
      };
    case "POST_LOGIN_DATA_REJECTED":
      return {
        ...state,
        loggingIn: false,
        loginRejected: true,
      };
    default:
      return {
        ...state,
      };
  }
}
