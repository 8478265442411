import React from "react";
import "./index.scss";

import { Link } from "react-router-dom";
import { Layout, Row, Col, Typography, List, Icon } from "antd";
import logo from "../../../assets/images/logo.png";

export default function Footer(props) {
  const paths = [
    {
      label: "Privacy",
      route: "/terms",
      method: "handlePrivacyModalShow"
    },
    {
      label: "Terms",
      route: "/terms",
      method: "handleTermsModalShow"
    },
    {
      label: "Contact",
      route: "/contact",
      method: "handleContactModalShow"
    },
  ];

  console.log(props);

  return (
    <Layout.Footer
      className={`admin-footer ${props.isSearch &&
        "admin-footer-padding"} ${props.other && "admin-footer-border-top"}`}
    >
      <Row type="flex" justify="space-between" align="middle">
        <Col lg={6} xs={24} align="center" className="w-logo">
          <img src={logo} alt="ipadama" className="logo" style={{"margin": "auto"}} />
        </Col>
        <Col lg={12} xs={24} align="center">
          <Typography.Paragraph style={{"margin": "auto"}}>
            This application is powered by ipadama 360
          </Typography.Paragraph>
        </Col>
        <Col xs={0} lg={6} align="left">
          
        </Col>
        <Col xs={24} align="center">
          
          {props.isSearch && (
            <Row justify="center">
              <Col xs={3} md={6}></Col>
              <Col xs={18} md={12}>
                <Row type="flex" justify="center">
                  <List
                    size="small"
                    itemLayout="horizontal"
                    dataSource={paths}
                    style={{ "width": "100%" }}
                    renderItem={(item) => (
                      <Col span={8} align="center">
                        <List.Item style={{ "textAlign": "center", "width": "50px !important" }}>
                          <a onClick={props[item.method]}>{item.label}</a>
                        </List.Item>
                      </Col>
                    )}
                  />
                </Row>
              </Col>
              <Col xs={3} md={6}></Col>
            </Row>
          )}
        </Col>
      </Row>
      
    </Layout.Footer>
  );
}
