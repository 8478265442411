import React, { Component } from "react";
import { Form, Layout, Card, Tabs, Modal } from "antd";
import "./index.scss";
import { getUser } from "../../utils/token";
import Appointments from "./components/Appointments";
import Calendar from "./components/Calendar";
import TimeSlots from "./components/TimeSlots";
import DayOffs from "./components/DayOffs";
import EmailTemplates from "./components/EmailTemplates";
import Doctors from "./components/Doctors";
import Users from "./components/Users";
import Images from "./components/Images";

const { Content } = Layout;
const { TabPane } = Tabs;

class Appointment extends Component {
  initialState = {};

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleTabChange = (e) => {
    this.props.history.push(`admin?tab=${e}`);
  };

  showModalResponse = (response, success, fail) => {
    if (response.error) {
      Modal.success({
        title: fail,
        content: "",
      });
    } else {
      Modal.success({
        title: success,
        content: "",
      });
    }
  };

  render() {
    const user = JSON.parse(getUser());

    const search = this.props.location.search;
    const tab = new URLSearchParams(search).get("tab");

    return (
      <Layout className="appointment-container">
        <Content className="appointment-header">{tab}</Content>
        <Content className="appointment-card">
          <Card
            title={
              <Tabs defaultActiveKey={tab} onChange={this.handleTabChange}>
                <TabPane tab="Appointments" key="Appointments">
                  <Appointments
                    {...this.props}
                    showModalResponse={this.showModalResponse}
                  />
                </TabPane>

                <TabPane tab="Calendar" key="Calendar">
                  <Calendar
                    {...this.props}
                    showModalResponse={this.showModalResponse}
                  />
                </TabPane>

                {user.is_admin ? (
                  <TabPane tab="Time Slots" key="Time Slots">
                    <TimeSlots
                      {...this.props}
                      showModalResponse={this.showModalResponse}
                    />
                  </TabPane>
                ) : null}

                {/* {user.is_admin ? (
                  <TabPane tab="Day Offs" key="Day Offs">
                    <DayOffs {...this.props} showModalResponse={this.showModalResponse} />
                  </TabPane>
                ) : null} */}

                {user.is_admin ? (
                  <TabPane tab="Email Templates" key="Email Templates">
                    <EmailTemplates
                      {...this.props}
                      showModalResponse={this.showModalResponse}
                    />
                  </TabPane>
                ) : null}

                {user.is_admin ? (
                  <TabPane tab="Doctors" key="Doctors">
                    <Doctors
                      {...this.props}
                      showModalResponse={this.showModalResponse}
                    />
                  </TabPane>
                ) : null}

                {user.is_admin ? (
                  <TabPane tab="Admin Users" key="Admin Users">
                    <Users
                      {...this.props}
                      showModalResponse={this.showModalResponse}
                    />
                  </TabPane>
                ) : null}

                {/* {user.is_admin ? (
                  <TabPane tab="Images" key="Images">
                    <Images
                      {...this.props}
                      showModalResponse={this.showModalResponse}
                    />
                  </TabPane>
                ) : null} */}
              </Tabs>
            }
          ></Card>
        </Content>
      </Layout>
    );
  }
}

const AppointmentForm = Form.create({ name: "appointment_form" })(Appointment);

export default AppointmentForm;
