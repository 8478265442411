const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)

    reader.onload = function () {
      resolve(reader.result)
    }

    reader.onerror = function (error) {
      reject(error)
    }
  })
}

const base64 = async file => {
  return await getBase64(file)
}

export default base64
