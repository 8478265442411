import React, { Component } from "react";
import { Form, Layout, Card, Row, Col, Modal, Input, Button } from "antd";
import "./index.scss";
import { getUser } from "../../utils/token";
import ImageUploader from "../shared/ImageUploader";

const { Content } = Layout;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.fileUploader = React.createRef();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitUpdateUser } = this.props;
    const selectedRow = JSON.parse(getUser());

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let params = {
          ...values,
        };

        if (!selectedRow.is_admin) {
          let media = null;
          let imageUrl =
            selectedRow && selectedRow.detail && selectedRow.detail.image
              ? selectedRow.detail.image
              : "";
          media = await this.fileUploader.current.handleUploadRequest();
          params.image = media && media.url ? media.url : imageUrl;
        }

        params.is_admin = selectedRow.is_admin;
        params.id = selectedRow.id;
        await onSubmitUpdateUser(params).then((response) => {
          if (response.error) {
            Modal.success({
              title: "Unable to update profile.",
              content: "",
            });
          } else {
            Modal.success({
              title: "Profile has been updated successfully",
              content: "",
              onCancel: (e) => {
                window.location.href = "/admin/profile";
              },
            });
            // console.log("response", response.response.data);
            localStorage.setItem(
              "USER",
              JSON.stringify(response.response.data)
            );
          }
        });

        this.props.form.resetFields();
      }
    });
  };

  componentDidMount() {
    this.props.onLoadUsersData();
  }

  // componentWillReceiveProps(nextProps) {
  //   const selectedRow = JSON.parse(getUser());
  //   const filterUser = nextProps.usersData.filter((a) => {
  //     if (a.id === selectedRow.id) {
  //       return true;
  //     }

  //     return false;
  //   });

  //   if (selectedRow !== filterUser[0]) {
  //     localStorage.setItem("USER", JSON.stringify(filterUser[0]));
  //   }
  // }

  render() {
    const selectedRow = JSON.parse(getUser());

    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Layout className="appointment-container">
        <Content className="appointment-header">My Profile</Content>
        <Content className="appointment-card">
          <Card>
            <Row type="flex" justify="center" gutter={30}>
              <Form onSubmit={this.handleSubmit} className="user-form">
                <Col span={24} style={{ textAlign: "center" }}>
                  <ImageUploader
                    match={this.props.match}
                    ref={this.fileUploader}
                    fileFolder="doctors"
                    buttonDesc="Upload Image"
                    imageUrl={
                      selectedRow && selectedRow.detail && selectedRow.detail.image
                        ? selectedRow.detail.image
                        : ""
                    }
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator("firstname", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter First Name!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.firstname : "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator("lastname", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Last Name!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.lastname : "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Username">
                    {getFieldDecorator("username", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Username!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.username : "",
                    })(<Input disabled={selectedRow ? true : false} />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Password (Leave blank if not to change)">
                    {getFieldDecorator("password", {
                      rules: [],
                      initialValue: selectedRow ? selectedRow.password : "",
                    })(<Input type="password" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator("email", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Email!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.email : "",
                    })(<Input type="email" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Mobile">
                    {getFieldDecorator("mobile", {
                      rules: [
                        {
                          required: true,
                          message: "Please enter Mobile!",
                        },
                      ],
                      initialValue: selectedRow ? selectedRow.mobile : "",
                    })(<Input />)}
                  </Form.Item>
                </Col>
                {selectedRow && !selectedRow.is_admin ? (
                  <Col span={12}>
                    <Form.Item label="Location">
                      {getFieldDecorator("location", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Location!",
                          },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.detail.location
                          : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                ) : null}
                {selectedRow && !selectedRow.is_admin ? (
                  <Col span={12}>
                    <Form.Item label="Specialization">
                      {getFieldDecorator("specialization", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Specialization!",
                          },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.detail.specialization
                          : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                ) : null}
                {selectedRow && !selectedRow.is_admin ? (
                  <Col span={12}>
                    <Form.Item label="Hospital">
                      {getFieldDecorator("hospital", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Hospital!",
                          },
                        ],
                        initialValue: selectedRow
                          ? selectedRow.detail.hospital
                          : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                ) : null}
                <Col span={24} align="right">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Row>
          </Card>
        </Content>
      </Layout>
    );
  }
}

const ProfileForm = Form.create({ name: "appointment_form" })(Profile);

export default ProfileForm;
