import React, { Component } from "react";
import {
  Form,
  Divider,
  Button,
  Layout,
  Row,
  Col,
  Table,
  Input,
  Modal,
  Icon,
  Select,
  Calendar,
} from "antd";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CsvCreator from "react-csv-creator";
import { getUser } from "../../../../utils/token";

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const ButtonGroup = Button.Group;

const dateToday = moment(new Date()).format("YYYY-MM-DD");
const currentMonth = moment(new Date()).format("YYYY-MM");

class Appointment extends Component {
  initialState = {
    showAssignmentModal: false,
    selectedRow: null,
    tableData: [],
    usersData: [],
    confirmData: [],
    showConfirmModal: false,
    confirmEmail: [],
    selectedDate: dateToday,
    selectedMonth: currentMonth,
    showReschedModal: false,
    slotsData: [],
    dayOffsData: [],
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onSubmitUpdateAppointment } = this.props;
    const { selectedRow } = this.state;

    this.props.form.validateFields(["user_id"], async (err, values) => {
      if (!err) {
        const params = {
          ...values,
        };

        params.id = selectedRow.id;
        await onSubmitUpdateAppointment(params).then((response) => {
          if (response.error) {
            Modal.success({
              title: "Unable to update Appointment.",
              content: "",
            });
          } else {
            Modal.success({
              title: "Appointment has been successfully updated",
              content: "",
            });
          }
        });

        this.props.form.resetFields();
        this.handleCloseModal();
      }
    });
  };

  handleSubmitResched = (e) => {
    e.preventDefault();

    const { onSubmitUpdateAppointment } = this.props;
    const { selectedTime, selectedDate, selectedRow } = this.state;

    this.props.form.validateFields([], async (err, values) => {
      if (!err) {
        let params = {
          ...values,
        };

        params.appointment_time = selectedTime;
        params.appointment_date = selectedDate;
        params.id = selectedRow.id;

        await onSubmitUpdateAppointment(params).then((response) => {
          if (response.error) {
            Modal.success({
              title: "Unable to update Appointment.",
              content: "",
            });
          } else {
            Modal.success({
              title: "Appointment has been successfully updated",
              content: "",
            });
          }
        });

        this.props.form.resetFields();
        this.handleCloseModal();
      }
    });
  };

  handleShowAssignmentModal = (row) => {
    this.setState({
      showAssignmentModal: true,
      selectedRow: row,
    });
  };

  handleShowReschedModal = (row) => {
    this.setState({
      showReschedModal: true,
      selectedRow: row,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showAssignmentModal: false,
      showReschedModal: false,
      selectedRow: null,
      showConfirmModal: false,
      confirmData: [],
    });
  };

  onConfirm = (row, status) => {
    const params = {
      id: row.id,
      status: status,
    };

    this.setState({ showConfirmModal: true, confirmData: params });
  };

  handleSubmitConfirm = (e) => {
    e.preventDefault();

    this.props.form.validateFields(
      ["email_subject", "email_content"],
      async (err, values) => {
        if (!err) {
          const params = {
            ...values,
            ...this.state.confirmData,
          };

          await this.props
            .onSubmitUpdateAppointmentStatus(params)
            .then((response) => {
              if (response.error) {
                Modal.success({
                  title: "Unable to confirm Appointment.",
                  content: "",
                });
              } else {
                Modal.success({
                  title: "Appointment has been successfully confirmed",
                  content: "",
                });
              }
            });

          this.props.form.resetFields();
          this.handleCloseModal();
        }
      }
    );
  };

  changeStatus = (row, status) => {
    const params = {
      id: row.id,
      status: status,
    };
    this.props.onSubmitUpdateAppointmentStatus(params).then((response) => {
      if (response.error) {
        Modal.warning({
          title: "Unable to update status of Appointment.",
          content: "",
        });
      } else {
        Modal.success({
          title: "Appointment status has been updated successfully",
          content: "",
        });
      }
    });
  };

  componentDidMount() {
    this.props.onLoadAppointmentsData();
    this.props.onLoadUsersData();
    this.props.onLoadEmailTemplatesData();
    this.props.onLoadSlotsData(currentMonth);
    this.props.onLoadDayOffsData();
  }

  handleSearch = (searchTerm, searchKeys) => {
    const convertStringDotNotation = (desc, obj) => {
      var arr = desc.split(".");
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    };
    const { appointmentsData } = this.props;
    const newArrayObj = appointmentsData;
    if (!searchTerm) {
      return this.setState({ tableData: newArrayObj });
    }
    let distributorArray = [];
    newArrayObj.forEach((rowItem, i) => {
      searchKeys.forEach((keys) => {
        let rowItemKeys = convertStringDotNotation(keys, { ...rowItem });
        let isFound =
          rowItemKeys &&
          rowItemKeys.toLowerCase().includes(searchTerm.toLowerCase());
        if (isFound) distributorArray.push(rowItem);
      });
    });
    const newArrayElement = distributorArray.filter(function(
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });
    this.setState({ tableData: newArrayElement });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.slotsData !== nextProps.slotsData) {
      this.setState({ slotsData: nextProps.slotsData });
    }

    if (this.props.dayOffsData !== nextProps.dayOffsData) {
      this.setState({ dayOffsData: nextProps.dayOffsData });
    }

    if (this.props.appointmentsData !== nextProps.appointmentsData) {
      this.setState({ tableData: nextProps.appointmentsData });
    }

    if (this.props.usersData !== nextProps.usersData) {
      this.setState({ usersData: nextProps.usersData });
    }

    if (this.props.emailTemplatesData !== nextProps.emailTemplatesData) {
      const emailTemplatesData = nextProps.emailTemplatesData;
      let confirmTemplate = emailTemplatesData.filter((row) => {
        if (row.is_admin !== 0 && row.type === 2) {
          return true;
        }

        return false;
      });

      this.setState({ confirmEmail: confirmTemplate[0] });
    }
  }

  replaceTime = (data) => {
    let newData = data.replace("12:", "0:");
    newData = newData.replace(":", "");
    newData = newData.replace(" ", "");
    newData = newData.replace(/am/gi, "1");
    newData = newData.replace(/pm/gi, "50000");

    return parseInt(newData);
  };

  handleDateSelection = (value) => {
    const dateSelected = moment(value).format("YYYY-MM-DD");
    const monthSelected = moment(value).format("YYYY-MM");

    this.setState({
      selectedDate: dateSelected,
      selectedMonth: monthSelected,
    });
  };

  handleTimeSelection = (time) => {
    this.setState({
      selectedTime: time,
    });
  };

  handleDateDisabling = (current) => {
    const { dayOffsData } = this.state;

    const calendarDate = moment(current).format("YYYY-MM-DD");

    const exist = dayOffsData.filter((day) => day.date === calendarDate);

    if (current <= moment().subtract(1, "days") || exist.length) {
      return true;
    }

    return false;
  };

  render() {
    const {
      showAssignmentModal,
      showReschedModal,
      selectedRow,
      tableData,
      usersData,
      confirmEmail,
      showConfirmModal,
      slotsData,
      selectedDate,
      selectedTime,
    } = this.state;
    const {
      isLoading,
      form: { getFieldDecorator },
    } = this.props;
    const user = JSON.parse(getUser());

    const dataSource = tableData;

    const columns = [
      {
        title: "Tracking Number",
        dataIndex: "tracking_number",
        key: "tracking_number",
        sorter: (a, b) => {
          const x = a.tracking_number.toLowerCase();
          const y = b.tracking_number.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "First Name",
        dataIndex: "firstname",
        key: "firstname",
        sorter: (a, b) => {
          const x = a.firstname.toLowerCase();
          const y = b.firstname.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Last Name",
        dataIndex: "lastname",
        key: "lastname",
        sorter: (a, b) => {
          const x = a.lastname.toLowerCase();
          const y = b.lastname.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "Schedule",
        dataIndex: "appointment_date",
        key: "appointment_date",
        align: "center",
        sorter: (a, b) => {
          const x = moment(a.appointment_date + " " + a.appointment_time)
            .format("X")
            .toString();
          const y = moment(b.appointment_date + " " + b.appointment_time)
            .format("X")
            .toString();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (tags, row) => {
          return (
            <span>
              {moment(row.appointment_date)
                .format("MMMM DD, YYYY, ")
                .toString() + row.appointment_time}
            </span>
          );
        },
      },
      {
        title: "Date Requested",
        dataIndex: "created_at",
        key: "created_at",
        align: "center",
        defaultSortOrder: "descend",
        sorter: (a, b) => {
          const x = moment(a.created_at)
            .format("X")
            .toString();
          const y = moment(b.created_at)
            .format("X")
            .toString();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
        render: (tags, row) => {
          return (
            <span>
              {moment(row.created_at)
                .format("MMMM DD, YYYY")
                .toString()}
            </span>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",
        width: 200,
        filters: [
          { text: "Pending", value: 0 },
          { text: "Confirmed", value: 1 },
          { text: "Cancelled", value: 2 },
          { text: "Completed", value: 3 },
        ],
        onFilter: (value, record) => record.status === value,
        render: (tags, row) => {
          let status = "Pending";
          switch (row.status) {
            case 0:
              status = "Pending";
              break;

            case 1:
              status = "Confirmed";
              break;

            case 2:
              status = "Cancelled";
              break;

            case 3:
              status = "Completed";
              break;

            default:
              status = "Pending";
              break;
          }
          return <span>{status}</span>;
        },
      },
      {
        title: "Mode",
        dataIndex: "mode",
        key: "mode",
        align: "center",
        width: 150,
        sorter: (a, b) => {
          const x = a.mode.toLowerCase();
          const y = b.mode.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "x",
        render: (data, i) => {
          return (
            <div className="col-action">
              <span>Show/Hide Details</span>
            </div>
          );
        },
      },
    ];

    const csvHeader = [
      {
        id: "count",
        display: "0",
      },
      {
        id: "tracking_number",
        display: "Tracking Number",
      },
      {
        id: "firstname",
        display: "First Name",
      },
      {
        id: "lastname",
        display: "Last Name",
      },
      {
        id: "email",
        display: "Email Address",
      },
      {
        id: "mobile",
        display: "Contact Number",
      },
      {
        id: "schedule_date",
        display: "Schedule",
      },
      {
        id: "request_date",
        display: "Request Date",
      },
      {
        id: "doctor",
        display: "Assigned Doctor",
      },
      {
        id: "mode",
        display: "Mode",
      },
      // {
      //   id: "doctor",
      //   display: "Referred By",
      // },
      // {
      //   id: "special_note",
      //   display: "Special Notes",
      // },
      {
        id: "status",
        display: "Status",
      },
    ];

    let csvRow = [];
    dataSource.forEach((row, index) => {
      let status = "Pending";
      switch (row.status) {
        case 0:
          status = "Pending";
          break;

        case 1:
          status = "Confirmed";
          break;

        case 2:
          status = "Cancelled";
          break;

        case 3:
          status = "Completed";
          break;

        default:
          status = "Pending";
          break;
      }

      const rowData = {
        count: index + 1,
        tracking_number: row.tracking_number,
        firstname: row.firstname,
        lastname: row.lastname,
        email: row.email,
        mobile: row.mobile,
        mode: row.mode,
        // doctor: row.doctor,
        // special_note: row.special_note,
        schedule_date:
          moment(row.appointment_date)
            .format("MMM. DD, YYYY h:mm:ss a")
            .toString() +
          " " +
          row.appointment_time,
        request_date: moment(row.created_at).format("MMM. DD, YYYY"),
        status: status,
        doctor: row.doctor
          ? row.doctor.firstname + " " + row.doctor.lastname
          : "<Unassigned>",
      };
      csvRow[index] = rowData;
    });

    const slots = slotsData.sort((a, b) => {
      const x = this.replaceTime(a.time);
      const y = this.replaceTime(b.time);
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    return (
      <div>
        <Layout>
          <Content style={{ padding: 20 }}>
            <Row>
              <Col xs={0} lg={12} align="right"></Col>
              <Col xs={24} lg={12} align="right">
                <ButtonGroup style={{ display: "flex" }}>
                  <Search
                    placeholder="Search"
                    onSearch={(value) =>
                      this.handleSearch(value, [
                        "tracking_number",
                        "firstname",
                        "lastname",
                        "appointment_date",
                        "mode",
                      ])
                    }
                    enterButton
                    style={{ margin: "10px" }}
                  />
                  <Button
                    type="primary"
                    style={{ margin: "10px", padding: "5px 5px" }}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    <CsvCreator
                      filename={
                        moment(Date()).format("MM-DD-YYYY") +
                        "_appointment-list.csv"
                      }
                      headers={csvHeader}
                      rows={csvRow}
                    >
                      <Icon type="download" /> Download CSV
                    </CsvCreator>
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  loading={isLoading}
                  onChange={() => {
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }}
                  expandRowByClick
                  expandedRowRender={(record) => {
                    return (
                      <Row>
                        <Col md={6}>
                          <Content className="action-content">
                            {record.status === 0 ? (
                              <Button
                                className={
                                  record.staff
                                    ? "btn-confirm"
                                    : "btn-confirm disabled"
                                }
                                disabled={!record.staff}
                                onClick={() => this.onConfirm(record, 1)}
                              >
                                Confirm
                              </Button>
                            ) : null}
                            {record.status === 0 ? (
                              <Button
                                className="btn-cancel"
                                onClick={() => this.changeStatus(record, 2)}
                              >
                                Cancel
                              </Button>
                            ) : null}
                            {record.status === 1 ? (
                              <Button
                                className={
                                  record.staff
                                    ? "btn-confirm"
                                    : "btn-confirm disabled"
                                }
                                disabled={!record.staff}
                                onClick={() => this.changeStatus(record, 3)}
                              >
                                Complete
                              </Button>
                            ) : null}

                            {record.status === 0 ? (
                              <h3
                                onClick={() =>
                                  this.handleShowAssignmentModal(record)
                                }
                              >
                                Assign to doctor
                              </h3>
                            ) : null}

                            {record.status === 0 || record.status === 1 ? (
                              <h3
                                onClick={() =>
                                  this.handleShowReschedModal(record)
                                }
                              >
                                Reschedule Appointment
                              </h3>
                            ) : null}
                          </Content>
                        </Col>
                        <Col md={18}>
                          <Row gutter={32}>
                            <Col className="expand-row-details" md={12}>
                              <h1>Email Address</h1>
                              <p>{record.email}</p>
                            </Col>
                            <Col className="expand-row-details" md={12}>
                              <h1>Contact Number</h1>
                              <p>{record.mobile}</p>
                            </Col>
                          </Row>
                          <Divider />
                          <Row gutter={32}>
                            <Col className="expand-row-details" md={12}>
                              <h1 className="expand-row-sub-details">
                                Requested last{" "}
                                {moment(record.created_at)
                                  .format("dddd, MMMM DD, YYYY")
                                  .toString()}
                              </h1>
                            </Col>
                            <Col className="expand-row-details" md={12}>
                              <h1>Assigned Doctor</h1>
                              <p>
                                {record.staff
                                  ? record.staff.firstname +
                                    " " +
                                    record.staff.lastname
                                  : "<Unassigned>"}
                              </p>
                            </Col>
                          </Row>

                          {user.is_admin ? <Divider /> : null}

                          {user.is_admin ? (
                            <Row gutter={32}>
                              {/* <Col className="expand-row-details" md={12}>
                                <h1>Referred By</h1>
                                <p>{record.doctor}</p>
                              </Col> */}
                              <Col className="expand-row-details" md={12}>
                                <h1>Special Notes</h1>
                                <p>{record.special_note}</p>
                              </Col>
                            </Row>
                          ) : null}

                          {record.remarks ? <Divider /> : null}

                          {record.remarks ? (
                            <Row gutter={32}>
                              <Col className="expand-row-details" md={24}>
                                <h1>Note</h1>
                                <p>{record.remarks}</p>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                      </Row>
                    );
                  }}
                />
              </Col>
            </Row>
          </Content>
        </Layout>

        <Modal
          title={`Assign Staff`}
          visible={showAssignmentModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form
                  onSubmit={this.handleSubmit}
                  className="assign-staff-form"
                >
                  <Col span={24}>
                    <Form.Item label="Doctor">
                      {getFieldDecorator("user_id", {
                        rules: [
                          { required: true, message: "Please select Doctor!" },
                        ],
                        initialValue:
                          selectedRow && selectedRow.user_id
                            ? selectedRow.user_id
                            : "",
                      })(
                        <Select placeholder="Select a Staff">
                          {usersData.map((row, index) => {
                            if (row.is_admin || !row.status) {
                              return null;
                            }
                            return (
                              <Option
                                value={row.id}
                                key={index}
                              >{`${row.firstname} ${row.lastname}`}</Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Assign
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>

        <Modal
          title={`Resched Appointment`}
          visible={showReschedModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
          width={600}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form
                  onSubmit={this.handleSubmitResched}
                  className="resched-form"
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    className="calendar"
                  >
                    <div className="form-label">Select Time and Date</div>
                    <Calendar
                      fullscreen={false}
                      disabledDate={this.handleDateDisabling}
                      headerRender={({
                        value,
                        type,
                        onChange,
                        onTypeChange,
                      }) => {
                        const start = 0;
                        const end = 12;
                        const monthOptions = [];

                        const current = value.clone();

                        const localeData = value.localeData();
                        const months = [];

                        for (let i = 0; i < 12; i++) {
                          current.month(i);
                          months.push(localeData.monthsShort(current));
                        }

                        for (let index = start; index < end; index++) {
                          monthOptions.push(months[index]);
                        }

                        const month = value.month();
                        const year = value.year();

                        const handleNextMonth = () => {
                          const newValue = value.clone();
                          newValue.month(parseInt(month + 1, 10));
                          onChange(newValue);

                          this.props.onLoadSlotsData(year + "-" + month);
                        };

                        const handlePrevMonth = () => {
                          const newValue = value.clone();
                          newValue.month(parseInt(month - 1, 10));
                          onChange(newValue);

                          this.props.onLoadSlotsData(year + "-" + month);
                        };

                        return (
                          <div className="calendar-control">
                            <label className="date-label">
                              <b style={{ color: "#FB4570" }}>
                                {moment(value).format("MMMM ")}
                              </b>
                              {moment(value).format("YYYY")}
                            </label>
                            <div>
                              <Icon
                                type="left"
                                onClick={handlePrevMonth}
                                className="date-control"
                              />
                              <Icon
                                type="right"
                                onClick={handleNextMonth}
                                className="date-control"
                              />
                            </div>
                          </div>
                        );
                      }}
                      onSelect={this.handleDateSelection}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="form-label">
                      {moment(selectedDate).format("dddd, MMMM DD, YYYY")}
                    </div>
                    <div className="appointment-times">
                      {slots.map((row, index) => {
                        if (
                          row.day !== moment(selectedDate).format("dddd") ||
                          !row.status
                        ) {
                          return "";
                        }

                        // appointment_date
                        let disabled = false;

                        const appointments = row.appointment.filter((app) => {
                          if (
                            app.appointment_date ===
                            moment(selectedDate)
                              .format("YYYY-MM-DD")
                              .toString()
                          ) {
                            return true;
                          }

                          return false;
                        });

                        appointments.sort((a, b) => {
                          const x = moment(a.created_at)
                            .format("X")
                            .toString();
                          const y = moment(b.created_at)
                            .format("X")
                            .toString();
                          if (x < y) {
                            return -1;
                          }
                          if (x > y) {
                            return 1;
                          }
                          return 0;
                        });

                        if (appointments.length >= row.slots) {
                          disabled = true;
                        }

                        let className =
                          selectedTime === row.time
                            ? "time-button time-selected"
                            : "time-button";

                        className = disabled
                          ? className + " disabled"
                          : className;

                        return (
                          <Button
                            className={className}
                            onClick={() => this.handleTimeSelection(row.time)}
                            key={index}
                            disabled={disabled}
                          >
                            {row.time}
                          </Button>
                        );
                      })}
                    </div>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Assign
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>

        <Modal
          title={`Confirm appointment and edit email content`}
          visible={showConfirmModal}
          onCancel={this.handleCloseModal}
          centered={true}
          footer={null}
          width={900}
          destroyOnClose={true}
        >
          <Layout>
            <Content className="contact-form-container">
              <Row type="flex" justify="center" gutter={30}>
                <Form
                  onSubmit={this.handleSubmitConfirm}
                  className="email-template-form"
                >
                  <Col span={24}>
                    <Form.Item label="Subject">
                      {getFieldDecorator("email_subject", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter Subject!",
                          },
                        ],
                        initialValue: confirmEmail ? confirmEmail.subject : "",
                      })(<Input />)}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item label="Content">
                      {getFieldDecorator("email_content", {
                        rules: [
                          {
                            required: true,
                            message: "Please enter content!",
                          },
                        ],
                        initialValue: confirmEmail ? confirmEmail.content : "",
                      })(
                        <ReactQuill
                          value={confirmEmail ? confirmEmail.content : ""}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Row>
                      <Col span={12}>##firstname## - first name of user</Col>
                      <Col span={12}>##lastname## - last name of user</Col>
                      <Col span={12}>##email## - email of user</Col>
                      <Col span={12}>##mobile## - mobile number of user</Col>
                      <Col span={12}>##book-date## - date of appointment</Col>
                      <Col span={12}>##book-time## - time of appointment</Col>
                      <Col span={12}>##tracking-number## - tracking number</Col>
                      <Col span={12}>##doctor## - Referring doctor</Col>
                      {/* <Col span={12}>##special-note## - Special notes</Col> */}
                      <Col span={12}>
                        ##consultation-mode## - preferred consultation mode
                      </Col>
                      <Col span={12}>
                        ##contact-mobile## - contact mobile of program
                      </Col>
                      <Col span={12}>
                        ##contact-email## - contact email of program
                      </Col>
                      <Col span={12}>##program-name## - program name</Col>
                      <Col span={12}>
                        ##doctor-firstname## - first name of doctor assigned
                      </Col>
                      <Col span={12}>
                        ##doctor-lastname## - last name of doctor assigned
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} align="right">
                    <Form.Item>
                      <Button
                        key="back"
                        onClick={this.handleCloseModal}
                        disabled={isLoading}
                      >
                        Close
                      </Button>
                      &nbsp;
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Col>
                </Form>
              </Row>
            </Content>
          </Layout>
        </Modal>
      </div>
    );
  }
}

const AppointmentForm = Form.create({ name: "day_off_form" })(Appointment);

export default AppointmentForm;
