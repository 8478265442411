import React from "react";
import "../../index.scss";
import { Modal, Button, Collapse } from "antd";

const { Panel } = Collapse;

export default class PrivacyModal extends React.Component {
  render() {
    const { visible, isModalShow } = this.props;
    return (
      <Modal
        className="scheduler-submit-modal"
        visible={visible}
        footer={null}
        closable={false}
        centered={true}
        width={900}
        onCancel={isModalShow}
        bodyStyle={{
          maxHeight: "90vh",
          position: "relative",
          display: "flex",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              textAlign: "justify",
              overflowY: "scroll",
              maxHeight: "95%",
              padding: "0 10px",
            }}
          >
            <div>
              <h1>PRIVACY Notice</h1>
            </div>
            <div>
              <h3>1. Introduction</h3>

              <p>
              Thank you for your interest in this website. EasyReach (“we”, “us” or “our”) values and protects your privacy rights.
              </p>

              <p>
              This Privacy Notice ("Notice") describes what Personal Data is collected from users ("you" or "the user") through EasyReacH ("the website"), and through the consultation that you will avail of using the website. This Notice also explains how we use such Personal Data.
              </p>

              <p>
              This Notice also describes your rights and how you can execute them.
              </p>

              <p>
              Personal Data is any personal information through which you can be directly or indirectly identified, e.g. your email address, your name, your contact number, or other information needed for the consultation.
              </p>

              <p>
              While this website is operated by EasyReach, any information you provide in this website shall be collected and processed by its partners Ipadama 360 Inc. and Professional Insights Marketing Services (PIMS).
              </p>

              <h3>
                2. What Personal Data do we collect and for which purposes do we
                use it?
              </h3>

              <p>
                The following lists the Personal Data that we collect from you
                through this website, and the purposes for which we will process
                it:
              </p>

              <table className="privacy-policy__table">
                <thead>
                  <th>
                    <b>Personal Data</b>
                  </th>
                  <th>
                    <b>Purpose/s for Processing</b>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>Name of Patient</td>
                    <td>
                    For booking appointments and to properly address the users during the consultation
                    </td>
                  </tr>
                  <tr>
                    <td>Patient’s email address</td>
                    <td>
                    Where appointment confirmation and details of consultation will be sent
                      <br />
                      Where our partners for this program send their marketing materials
                    </td>
                  </tr>
                  <tr>
                    <td>Patient’s contact numbers</td>
                    <td>
                    Where you may be reached to conduct the consultation
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Patient’s medical history
                    </td>
                    <td>
                    Will be used as a reference to properly conduct the consultation
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3>3. Data Processing</h3>

              <p>
              Processing is hereby understood to include any operation or any set of operations performed upon personal information including, but not limited to, the collection, recording, organization, storage, sharing, updating or modification, retrieval, consultation, use, consolidation, blocking, erasure or destruction of data. Processing would include both manual and automated handling of personal information and storage and data transfers using various means including but limited to physical methods as well as electronic via information and communications systems employed by EasyReach and its partners. The information that you will share will be used for the purpose indicated above. This information shall be kept confidential at all times and only authorized personnel of EasyReach , its partner Ipadama 360 Inc. and PIMS. will have access to your information. We implement reasonable and appropriate technical, physical, and organizational measures to prevent loss, destruction, misuse or alteration of your personal information.
              </p>

              <h3>4. Sharing personal data with third parties</h3>

              <p>
              We will share your Personal Data to third parties when we engage such third parties that use Personal Data to provide the services that you availed of or requested, on our behalf and according to our instructions. We also share Personal Data with our business partners Ipadama 360 Inc. and PIMS to fulfill the purpose/s, for which you have agreed to have your Personal Data processed, in accordance with this Notice.
              </p>

              <h3>5. Data retention</h3>

              <p>
              We will retain Personal Data as required by legal obligation, or for the period of time that is required to provide the service/s you have requested or availed of, or as long as required for the specific business purpose or purposes for which it was collected.
              </p>

              <h3>6. Right to access, review, correct and object</h3>

              <p>
              You are entitled to review and rectify any incorrect or outdated Personal Data we store, and you may withdraw your consent to the use of your data at any time, affecting all future use. You can also request a copy of information we store about you. All you need to do is to send an e- mail to: appointment@easyreach.phFurthermore, you have the right to lodge a complaint with the competent data protection authority.
              </p>

              <h3>7. Security</h3>

              <p>
              We follow industry standard practices to protect the confidentiality, integrity, and availability of data, always complying with applicable laws e.g. by access controls. We strive to update our security measures on a regular basis to keep track of new industry standards. Our data protection measures are continually audited and updated, reflecting the state of the art and the technical developments.
              </p>

              <h3>8. Cookies used on this website</h3>

              <p>
              The website uses cookies to help manage your preferences. Cookies also ensure that certain features function as intended and in a user-friendly manner. You may opt to block or remove cookies but doing so might limit the performance of certain sections of the website.
              </p>

              <h3>9. Contact</h3>

              <p>
              By providing your personal information, registering and availing of the services provided by EasyReach, you hereby warrant that you understand your rights and obligations under the Data Privacy Act and its implementing rules and regulations and that you shall hold EasyReach and its partners Ipadama 360 Inc. and PIMS. and assigns free and harmless from any claim for liability or damages with regard to the processing and collection of your Personal Data.
                <b>
                  If you have questions or suggestions, please feel free to
                  contact our local Data Privacy Officer through this email:
                </b>
                jaguiao@profinsights.biz
              </p>

              <h3>CONTACT US</h3>

              <p>
                You may also contact us at <b>0919-078-5956 (Smart)</b> and
                <b>0961-009-9696 (Smart)</b>
              </p>
            </div>
          </div>
          <Button className="close-button" onClick={() => isModalShow()}>
            CLOSE
          </Button>
        </div>
      </Modal>
    );
  }
}
